<template>
  <div class="reservation">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/tables'"></back-button>
        <div class="title">{{ $t("reserve.table_combos") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <button @click="editTable({})" class="fill">
        {{ $t("reserve.new_combo") }}
      </button>
      <div
        v-for="(table_combination, index) in table_combinations"
        :key="index"
        class="btn"
      >
        <div class="head">
          <div class="active-text">{{ table_combination.name }}</div>
          <div class="actions">
            <button
              @click="deleteTable(table_combination)"
              class="icon delete"
            ></button>
            <button
              @click="editTable(table_combination)"
              class="icon edit"
            ></button>
          </div>
        </div>
        <div class="description">
          <span>
            {{ $t("reserve.capacity") + ": " + table_combination.capacity }}
          </span>
          <span>
            {{ $t("reserve.minimum") + ": " + table_combination.minimum }}
          </span>
        </div>
      </div>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
      <table-combination-modal
        @closed_edit_modal="closedModal"
        :table_combination="selected_table_combination"
        :tables="tables"
      ></table-combination-modal>
      <delete-table-combination-modal
        @closed_delete_model="closedModal"
        :table_combination="deleting_table_combination"
      ></delete-table-combination-modal>
    </div>
  </div>
</template>
<script>
import CallsModal from "../../components/restaurant/CallsModal.vue";
import CallWaiter from "../../components/restaurant/CallWaiter.vue";
import TableCombinationModal from "../../components/restaurant/TableCombinationModal.vue";
import DeleteTableCombinationModal from "../../components/restaurant/DeleteTableCombinationModal.vue";
import BackButton from "../../components/common/BackButton.vue";

export default {
  components: {
    CallWaiter,
    CallsModal,
    BackButton,
    TableCombinationModal,
    DeleteTableCombinationModal,
  },
  mounted() {
    this.fetch();
    this.fetchTables();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(
          this.$hostname + "/table-combinations",
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.table_combinations = resp.data.result;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchTables() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/tables", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.tables = resp.data.result;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    editTable(table_combination) {
      this.$router
        .push({
          query: { table_combination: table_combination.id },
        })
        .catch(() => {});
      this.selected_table_combination = table_combination;
      this.showModal("table_combination_modal");
    },
    deleteTable(table) {
      this.deleting_table_combination = table;
      this.showModal("delete_table_combination_modal");
    },
    closedModal(val) {
      if (val) {
        this.fetch();
      }
    },
  },
  data() {
    return {
      tables: [],
      table_combinations: [],
      selected_table_combination: {},
      deleting_table_combination: {},
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 100vh;
  overflow-y: scroll;
  gap: 8px;
}
.btn {
  padding: 12px 8px;
  font-size: 14px;
  flex-direction: column;
  gap: 12px;
}

.fill {
  min-height: 48px;
}

.head,
.description {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.edit {
  background-image: url("../../assets/restaurant/home/pen_gray.svg");
}

.delete {
  background-image: url("../../assets/restaurant/home/trash_gray.svg");
}
</style>
