<template>
  <div class="account">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>

        <div class="title">{{ $t("home.settings") }}</div>

        <call-waiter></call-waiter>
      </div>
    </div>

    <div class="content">
      <!-- <div class="section-1">
        <router-link to="/profile">
          <div class="btn">
            <div class="left">
              <div class="icon profile"></div>
              <h4 class="caption">{{ $t("home.profile") }}</h4>
            </div>
            <div class="icon arrow"></div>
          </div>
        </router-link>
      </div> -->
      <div class="section-2">
        <translate></translate>
      </div>
      <div class="section-3">
        <div @click="showModal('contact_us_modal')" class="btn">
          <div class="left">
            <div class="icon contact"></div>
            <h4 class="caption">{{ $t("settings.contact") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </div>
        <div @click="showModal('about_modal')" class="btn">
          <div class="left">
            <div class="icon about"></div>
            <h4 class="caption">{{ $t("settings.about") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </div>
        <div @click="showModal('privacy_modal')" class="btn">
          <div class="left">
            <div class="icon privacy"></div>
            <h4 class="caption">{{ $t("settings.privacy_policy") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </div>
      </div>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
      <privacy-modal></privacy-modal>
      <about-us></about-us>
      <contact-us-modal></contact-us-modal>
    </div>
  </div>
</template>
<script>
import Translate from "../components/common/Translate.vue";
import PrivacyModal from "../components/common/PrivacyModal.vue";
import AboutUs from "../components/common/AboutUs.vue";
import ContactUsModal from "../components/restaurant/ContactUsModal.vue";
import BackButton from "../components/common/BackButton.vue";
import CallWaiter from "../components/restaurant/CallWaiter.vue";
import CallsModal from "../components/restaurant/CallsModal.vue";

export default {
  components: {
    Translate,
    PrivacyModal,
    AboutUs,
    ContactUsModal,
    BackButton,
    CallWaiter,
    CallsModal,
  },
  mounted() {
    this.getNewUpdate();
  },
};
</script>
<style scoped>
.content {
  padding-top: 64px;
}
.container {
  justify-content: space-between;
}
.btn {
  cursor: pointer;
}

.section-1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid #f0edf2;
  padding-bottom: 16px;
}
.section-2 {
  border-bottom: 1px solid #f0edf2;
  padding: 16px 0;
}
.section-3 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
}

.profile {
  background-image: url("../assets/account/profile.svg");
}

.address {
  background-image: url("../assets/account/address.svg");
}

.section-3 .left .icon {
  background-color: #ffeeeb;
}

.section-3 .arrow {
  background-image: url("../assets/common/right_orange.svg");
  width: 24px;
}

.contact {
  background-image: url("../assets/account/contact.svg");
}
.about {
  background-image: url("../assets/account/about.svg");
}
.privacy {
  background-image: url("../assets/account/privacy.svg");
}
</style>
