import { render, staticRenderFns } from "./LogoutModal.vue?vue&type=template&id=304b85fa&scoped=true&"
import script from "./LogoutModal.vue?vue&type=script&lang=js&"
export * from "./LogoutModal.vue?vue&type=script&lang=js&"
import style0 from "./LogoutModal.vue?vue&type=style&index=0&id=304b85fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "304b85fa",
  null
  
)

export default component.exports