<template>
  <div class="info">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/info'"></back-button>
        <div class="title">{{ $t("home.social_links") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <!-- social and links -->

      <div class="form">
        <v-text-field
          v-model="website"
          filled
          :label="$t('information.website')"
        ></v-text-field>

        <v-text-field
          v-model="google_maps"
          filled
          label="Google Maps"
        ></v-text-field>

        <v-text-field v-model="facebook" filled label="Facebook"></v-text-field>

        <v-text-field
          v-model="instagram"
          filled
          label="Instagram"
        ></v-text-field>

        <v-text-field v-model="tiktok" filled label="TikTok"></v-text-field>

        <v-text-field v-model="twitter" filled label="Twitter"></v-text-field>
      </div>
      <button @click="done()" class="fill">{{ $t("information.save") }}</button>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../../components/common/BackButton.vue";
import CallsModal from "../../../components/restaurant/CallsModal.vue";
import CallWaiter from "../../../components/restaurant/CallWaiter.vue";

export default {
  components: {
    BackButton,
    CallWaiter,
    CallsModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/info", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();

          this.facebook = resp.data.info[0].facebook;
          this.instagram = resp.data.info[0].instagram;
          this.twitter = resp.data.info[0].twitter;
          this.website = resp.data.info[0].website;
          this.tiktok = resp.data.info[0].tiktok;
          this.google_maps = resp.data.info[0].google_maps;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    save() {
      let fd = new FormData();

      fd.append("facebook", this.facebook ? this.facebook : "");
      fd.append("instagram", this.instagram ? this.instagram : "");
      fd.append("twitter", this.twitter ? this.twitter : "");
      fd.append("website", this.website ? this.website : "");
      fd.append("tiktok", this.tiktok ? this.tiktok : "");
      fd.append("google_maps", this.google_maps ? this.google_maps : "");

      this.global_loading.show = true;
      this.$http
        .post(this.$hostname + "/socials-info", fd, this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    done() {
      this.save();
      this.backToHome();
    },
    backToHome() {
      this.$router.push("/info");
    },
  },
  data() {
    return {
      website: "",
      twitter: "",
      facebook: "",
      instagram: "",
      tiktok: "",
      google_maps: "",
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}

.content {
  height: 100vh;
  overflow-y: scroll;
  padding: 78px 16px 96px;
}
.form {
  padding: 16px 0 0;
}

button.fill {
  min-height: 48px;
  margin-top: 12px;
}
</style>