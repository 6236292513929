var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar",attrs:{"id":"navigation"}},[_c('div',{staticClass:"container"},[_c('router-link',{attrs:{"to":"/orders"}},[_c('div',{staticClass:"btn"},[_c('div',{class:[
            _vm.$route.name == 'Orders' ? 'active-orders' : 'deactive-orders',
            'icon' ]},[(_vm.restaurant_new_orders.data.length)?_c('span',{staticClass:"badge"},[_vm._v("•")]):_vm._e()]),_c('div',{class:[
            _vm.$route.name == 'Orders' ? 'active-text' : 'deactive-text',
            'caption' ]},[_vm._v(" "+_vm._s(_vm.$t("cart.orders"))+" ")])])]),_c('router-link',{attrs:{"to":"/reservation"}},[_c('div',{staticClass:"btn"},[_c('div',{class:[
            _vm.$route.name == 'Reservation'
              ? 'active-reservation'
              : 'deactive-reservation',
            'icon' ]},[(_vm.restaurant_reservations.pending.length)?_c('span',{staticClass:"badge"},[_vm._v("•")]):_vm._e()]),_c('div',{class:[
            _vm.$route.name == 'Reservation' ? 'active-text' : 'deactive-text',
            'caption' ]},[_vm._v(" "+_vm._s(_vm.$t("navbar.reservation"))+" ")])])]),_c('router-link',{attrs:{"to":"/history"}},[_c('div',{staticClass:"btn"},[_c('div',{class:[
            _vm.$route.name == 'History' ? 'active-history' : 'deactive-history',
            'icon' ]}),_c('div',{class:[
            _vm.$route.name == 'History' ? 'active-text' : 'deactive-text',
            'caption' ]},[_vm._v(" "+_vm._s(_vm.$t("home.history"))+" ")])])]),(_vm.user_type === 'manager')?_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"btn"},[_c('div',{class:[
            _vm.management_routes.includes(_vm.$route.name)
              ? 'active-settings'
              : 'deactive-settings',
            'icon' ]}),_c('div',{class:[
            _vm.management_routes.includes(_vm.$route.name)
              ? 'active-text'
              : 'deactive-text',
            'caption' ]},[_vm._v(" "+_vm._s(_vm.$t("navbar.management"))+" ")])])]):_vm._e()],1),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('audio',{attrs:{"controls":"","id":"notif"}},[_c('source',{attrs:{"src":require("../../assets/restaurant/bell.mp3"),"type":"audio/mpeg"}}),_vm._v(" Your browser does not support the audio element. ")])}]

export { render, staticRenderFns }