<template>
  <div class="history">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/reservation'"></back-button>

        <div class="title">{{ $t("home.history") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <div v-for="reserve in reserves" :key="reserve.id" class="btn">
        <div class="row">
          <div class="datetime">{{ formatDatetime(reserve.reserved_at) }}</div>
          <div class="people">{{ reserve.people + " People" }}</div>
        </div>
        <div class="row">
          <div class="name">
            {{ reserve.user_id ? reserve.user.name : reserve.full_name }}
          </div>
          <a
            :href="
              'tel:' +
              (reserve.user_id
                ? reserve.user.phone_number
                : reserve.phone_number)
            "
          >
            <div class="phone">
              {{
                reserve.user_id
                  ? reserve.user.phone_number
                  : reserve.phone_number
              }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import CallWaiter from "../../components/restaurant/CallWaiter.vue";
import CallsModal from "../../components/restaurant/CallsModal.vue";
import BackButton from "../../components/common/BackButton.vue";

export default {
  components: {
    CallWaiter,
    BackButton,
    CallsModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(
          this.$hostname + "/history-reservations",
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();

          this.reserves = resp.data.reservations;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      reserves: [],
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 100%;
  /* overflow-y: scroll; */
  gap: 8px;
}
.btn {
  padding: 12px 8px;
  font-size: 14px;
  flex-direction: column;
  gap: 12px;
}
.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.datetime,
.people {
  color: #776282;
}
.name,
.phone {
  color: #b3b3b3;
}
</style>
