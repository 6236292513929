<template>
  <div class="info">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/info'"></back-button>
        <div class="title">{{ $t("navbar.reservation") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <router-link class="btn tables" to="/tables">
        <div class="left">
          <div class="icon"></div>
          <h4 class="caption">{{ $t("reserve.tables") }}</h4>
        </div>
        <div class="icon arrow"></div>
      </router-link>

      <router-link class="btn invalid-times" to="/invalid-times">
        <div class="left">
          <div class="icon"></div>
          <h4 class="caption">{{ $t("reserve.invalid_times") }}</h4>
        </div>
        <div class="icon arrow"></div>
      </router-link>

      <router-link class="btn settings" to="/reservation-settings">
        <div class="left">
          <div class="icon"></div>
          <h4 class="caption">{{ $t("home.settings") }}</h4>
        </div>
        <div class="icon arrow"></div>
      </router-link>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../../components/common/BackButton.vue";
import CallsModal from "../../../components/restaurant/CallsModal.vue";
import CallWaiter from "../../../components/restaurant/CallWaiter.vue";

export default {
  components: {
    BackButton,
    CallWaiter,
    CallsModal,
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}

.content {
  height: 100vh;
  overflow-y: scroll;
  gap: 8px;
  padding: 78px 16px 96px;
}
.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
}

.tables .left .icon {
  background-image: url("../../../assets/restaurant/home/tables.png");
}
.settings .left .icon {
  background-image: url("../../../assets/restaurant/navbar/settings_on.svg");
}
.invalid-times .left .icon {
  background-image: url("../../../assets/restaurant/home/invalid_times.png");
}
</style>
