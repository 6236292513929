import Vue from "vue";
import moment from "moment";

export default Vue.mixin({
  updated() {
    const every_input = document.querySelectorAll("input[type=text]");
    for (let i = 0; i < every_input.length; i++) {
      every_input[i].addEventListener("focus", () => {
        this.hideNav();
      });
      every_input[i].addEventListener("blur", () => {
        this.showNav();
      });
    }
    const every_textarea = document.getElementsByTagName("textarea");
    for (let i = 0; i < every_textarea.length; i++) {
      every_textarea[i].addEventListener("focus", () => {
        this.hideNav();
      });
      every_textarea[i].addEventListener("blur", () => {
        this.showNav();
      });
    }
  },
  methods: {
    getNewUpdate() {
      this.$http
        .get(this.$hostname + "/latest-version")
        .then((resp) => {
          if (
            localStorage.latest_version &&
            resp.data.version != localStorage.latest_version
          ) {
            if (confirm("New version of the app is available. Refresh now?")) {
              localStorage.latest_version = resp.data.version;
              window.location.reload(true);
            }
          } else {
            localStorage.latest_version = resp.data.version;
          }
        })
        .catch((err) => {
          console.error(err.response);
        });
    },
    decodeUserIdByToken() {
      if (localStorage.restaurant_token || localStorage.token) {
        if (localStorage.restaurant_token) {
          var base64Url = localStorage.restaurant_token.split(".")[1];
        } else if (localStorage.token) {
          var base64Url = localStorage.token.split(".")[1];
        }
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        // console.log(jsonPayload);

        return JSON.parse(jsonPayload).sub;
      }
    },
    isItiOS() {
      return (
        navigator.vendor.match(/apple/i) &&
        !navigator.userAgent.match(/crios/i) &&
        !navigator.userAgent.match(/fxios/i) &&
        !navigator.userAgent.match(/Opera|OPT\//)
      );
    },
    iOSBottomGapFixer(class_name) {
      const actualHeight = window.innerHeight;
      const elementHeight = document.querySelector(class_name).clientHeight;

      const barHeight = elementHeight - actualHeight;

      let isSafari =
        navigator.vendor.match(/apple/i) &&
        !navigator.userAgent.match(/crios/i) &&
        !navigator.userAgent.match(/fxios/i) &&
        !navigator.userAgent.match(/Opera|OPT\//);

      let container = document.querySelector(class_name);

      if (isSafari) {
        // Safari browser is used
        container.style.paddingBottom = "80px";
      } else {
        if (barHeight > 0) {
          container.style.height = `${actualHeight}px`;
        }
        // Other browser is used
      }
    },
    hideNav() {
      document.getElementById("navigation").style.display = "none";
    },
    showNav() {
      document.getElementById("navigation").style.display = "flex";
    },
    showLanguages() {
      let open = document.getElementById("open_languages");
      let languages = document.getElementById("languages");

      if (languages.style.height) {
        languages.style.height = null;

        languages.style.display = "none";

        open.style.transform = "rotate(0deg)";

        return false;
      } else {
        languages.style.height = "50px";

        languages.style.display = "flex";

        open.style.transform = "rotate(180deg)";

        return true;
      }
    },
    toggleShowPassword(id_password) {
      let password = document.getElementById(id_password);

      if (password.type == "password") {
        password.type = "text";
        return false;
      } else {
        password.type = "password";
        return true;
      }
    },
    showModal(modal_id) {
      let modal = document.getElementById(modal_id);

      const blur_background = document.getElementById("blur_background");

      blur_background.style.display = "block";

      // this.blur_background.show = true

      modal.style.display = "flex";
      setTimeout(() => {
        modal.style.bottom = "0%";
      }, 100);

      // When the user clicks anywhere outside of the modal, close it
      if (this.isItiOS()) {
        blur_background.addEventListener(
          "click",
          (event) => {
            if (event.target.id == "blur_background") {
              this.$router.replace({ query: null });

              setTimeout(() => {
                modal.style.bottom = "-100%";
              }, 100);
              setTimeout(() => {
                modal.style.display = "none";
                blur_background.style.display = "none";
                // this.blur_background.show = false
              }, 300);
              return 0;
            }
          }
          // },
          // false
        );
      } else {
        blur_background.addEventListener(
          "click",
          (event) => {
            if (event.target.id == "blur_background") {
              this.$router.replace({ query: null });

              setTimeout(() => {
                modal.style.bottom = "-100%";
              }, 100);
              setTimeout(() => {
                modal.style.display = "none";

                blur_background.style.display = "none";

                // blur_background.style.left = '-100%'
                // this.blur_background.show = false
              }, 300);
              return 0;
            }
          },
          false
        );
      }
    },
    closeModal(modal_id) {
      let modal = document.getElementById(modal_id);

      this.$router.replace({ query: null }).catch(() => {});

      const blur_background = document.getElementById("blur_background");

      setTimeout(() => {
        modal.style.bottom = "-100%";
      }, 100);

      setTimeout(() => {
        modal.style.display = "none";

        blur_background.style.display = "none";
      }, 300);
    },
    toggleSwitch(option) {
      let switcher = document.getElementById("switch_selector");
      if (option) {
        switcher.style.left = "49.5%";
      } else {
        switcher.style.left = "4px";
      }
      return !option;
    },
    tripleSwitch(option) {
      let switcher = document.getElementById("switch_selector");
      if (option == 2) {
        switcher.style.left = "33%";
      } else if (option == 1) {
        switcher.style.left = "4px";
      } else {
        switcher.style.left = "65%";
      }
      return option;
    },
    formatPrice(price) {
      price = price ? parseFloat(price).toFixed(2) : "0,00";
      return price.toString().replace(".", ",") + " €";
    },
    replaceDot(price) {
      price = price ? parseFloat(price).toFixed(2) : "";
      return price.toString().replace(".", ",");
    },
    replaceComma(price) {
      return parseFloat(price.toString().replace(",", "."));
    },
    totalPrice(items) {
      let total = 0;

      if (items) {
        items.forEach((element) => {
          total +=
            element.item.price -
            (element.item.price * element.item.discount) / 100;

          if (element.drinks.length) {
            element.drinks.forEach((drink) => {
              total += drink.price;
            });
          }

          if (element.extras.length) {
            element.extras.forEach((extra) => {
              total += extra.price;
            });
          }

          if (element.compulsories.length) {
            element.compulsories.forEach((compulsory) => {
              compulsory.sub_compulsories.forEach((sub_compulsory) => {
                total += sub_compulsory.price;
              });
            });
          }
        });
      }

      return parseFloat(total).toFixed(2);
    },
    formatDatetime(datetime) {
      return moment(datetime).format("D.M.YYYY - HH:mm");
    },
    gotoHome() {
      this.$router.push("/");
    },
    printOrder(order) {
      {
        // window.print();
        var mywindow = window.open("", "PRINT", "height=400,width=600");

        mywindow.document.write(
          "<html><head><title>https://nordantia.com</title></head><body>"
        );

        mywindow.document.write("<center><h3>Nordantia #" + order.id + "</h3>");

        mywindow.document.write("<hr/>");

        mywindow.document.write("<h4>" + order.restaurant.title + "</h4>");
        mywindow.document.write(
          "<h4>" + this.formatDatetime(order.created_at) + "</h4>"
        );

        mywindow.document.write("<hr/>");

        if (order.user) {
          mywindow.document.write(`<p>` + order.user.name + "</p>");
          mywindow.document.write(`<p>` + order.user.email + "</p>");
          if (order.user.phone_number) {
            mywindow.document.write(`<p>` + order.user.phone_number + "</p>");
          }

          if (order.order_type == "delivery") {
            mywindow.document.write(
              `<p>${this.$t("account.address")}: ` +
                order.address.description +
                (order.address.zip_code ? ", " + order.address.zip_code : "") +
                (order.address.city ? " " + order.address.city : "") +
                "</p>"
            );
            mywindow.document.write(
              `<p>${this.$t("information.door_code")}: ` +
                order.address.door_code +
                "</p>"
            );

            mywindow.document.write(
              `<p>${this.$t("information.phone_number")}: ` +
                order.address.phone_number +
                "</p>"
            );
            mywindow.document.write(
              `<p>${this.$t("order.delivery_fee")}: ` +
                this.formatPrice(order.delivery_charge) +
                "</p>"
            );
          }

          if (order.night_shift_charge > 0) {
            mywindow.document.write(
              `<p>${this.$t("order.night_shift")} ` +
                this.formatPrice(order.night_shift_charge) +
                "</p>"
            );
          }

          if (order.minimum_order_charge > 0) {
            mywindow.document.write(
              `<p>Pienen tilauksen maksu: ` +
                this.formatPrice(order.minimum_order_charge) +
                "</p>"
            );
          }

          mywindow.document.write("<hr/>");
        }

        mywindow.document.write(
          "<p>" +
            this.$t("order.payment_method") +
            (order.payment_method == "mobile"
              ? "Mobile"
              : order.payment_method == "online"
              ? "Online"
              : order.payment_method == "cash"
              ? this.$t("order.cash_pay")
              : order.payment_method == "at_place"
              ? this.$t("cart.at_place")
              : "Not defined") +
            "<span>(" +
            (order.payment_status == 1
              ? this.$t("order.paid")
              : this.$t("cart.unpaid")) +
            ")</span>" +
            "</p>"
        );

        mywindow.document.write(
          "<p>" +
            this.$t("order.order_type") +
            ": " +
            (order.order_type == "pick_up"
              ? this.$t("order.pick_up")
              : order.order_type == "delivery"
              ? this.$t("order.delivery")
              : this.$t("order.at_restaurant") +
                " " +
                this.$t("order.table") +
                " " +
                order.table_number) +
            "</p>"
        );

        mywindow.document.write("</center><hr/>");
        mywindow.document.write("<hr/>");
        mywindow.document.write(
          order.json_items.length + " " + this.$t("order.portions")
        );
        mywindow.document.write("<hr/>");

        mywindow.document.write("<dl>");

        order.json_items.forEach((element) => {
          mywindow.document.write(
            "<dt>" +
              element.item.title +
              (element.item.category
                ? " (" + element.item.category.title + ")"
                : "") +
              " " +
              (element.item.discount > 0
                ? `<s>${this.formatPrice(element.item.price)}</s>` +
                  this.formatPrice(
                    element.item.price -
                      (element.item.price * element.item.discount) / 100
                  )
                : this.formatPrice(element.item.price)) +
              "</dt>"
          );

          if (element.extras.length) {
            mywindow.document.write(
              "<dd> " + this.$t("cart.extras") + ":</dd>"
            );
            element.extras.forEach((extra) => {
              mywindow.document.write(
                "<dd> " +
                  extra.title +
                  " " +
                  this.formatPrice(extra.price) +
                  "</dd>"
              );
            });
          }

          if (element.compulsories.length) {
            element.compulsories.forEach((compulsory) => {
              mywindow.document.write("<dd> " + compulsory.title + ": ");
              compulsory.sub_compulsories.forEach((sub_compulsory) => {
                mywindow.document.write(
                  "<div> -" +
                    sub_compulsory.title +
                    " " +
                    this.formatPrice(sub_compulsory.price) +
                    "</div>"
                );
              });
              mywindow.document.write("</dd>");
            });
          }

          if (element.drinks.length) {
            mywindow.document.write(
              "<dd> " + this.$t("cart.drinks") + ":</dd>"
            );

            element.drinks.forEach((drink) => {
              mywindow.document.write(
                "<dd> " +
                  drink.title +
                  " " +
                  this.formatPrice(drink.price) +
                  "</dd>"
              );
            });
          }
          mywindow.document.write("<hr/>");
        });

        mywindow.document.write("</dl>");

        mywindow.document.write("<hr/>");
        if (order.comment) {
          mywindow.document.write(
            "<p>" + this.$t("order.comment") + ": " + order.comment + "</p>"
          );
        }
        mywindow.document.write("<hr/>");

        mywindow.document.write(
          "<b>" +
            ` ${this.$t("cart.total_price")}: ` +
            this.formatPrice(
              parseFloat(this.totalPrice(order.json_items)) +
                (order.order_type == "delivery" ? order.delivery_charge : 0) +
                parseFloat(order.night_shift_charge) +
                parseFloat(order.minimum_order_charge)
            ) +
            "</b>"
        );

        // mywindow.document.write(document.getElementById("print").innerHTML);
        mywindow.document.write("<br><br>");
        mywindow.document.write("<hr/>");

        mywindow.document.write("<center>");
        mywindow.document.write("<p>Nordantia Oy<p>");
        mywindow.document.write("<p>041 3147604<p>");

        mywindow.document.write("<br><br>");

        mywindow.document.write("</body></html>");

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        // mywindow.close();

        // return true;
      }
    },
    // expand order in history of restaurant
    expandOrder(id) {
      let open = document.getElementById("open_order-" + id);
      let order = document.getElementById("order-" + id);

      if (order.style.height === "fit-content") {
        order.style.height = "84px";

        open.style.transform = "rotate(0deg)";

        return false;
      } else {
        order.style.height = "fit-content";

        open.style.transform = "rotate(180deg)";

        return true;
      }
    },
    // expand week in history of restaurant
    expandWeek(week, month) {
      let open = document.getElementById("open_week" + week);
      let box = document.getElementById("week-" + week);

      if (box.style.height == "auto") {
        box.style.height = "40px";

        open.style.transform = "rotate(0deg)";

        return false;
      } else {
        box.style.height = "auto";

        open.style.transform = "rotate(180deg)";

        this.closeOtherWeeks(week, month);

        return true;
      }
    },
    closeOtherWeeks(except_week, month) {
      for (let index = 1; index <= 4; index++) {
        if (index + "-" + month !== except_week) {
          let open = document.getElementById("open_week" + index + "-" + month);
          let box = document.getElementById("week-" + index + "-" + month);

          if (box.style.height == "auto") {
            box.style.height = "40px";

            open.style.transform = "rotate(0deg)";
          }
        }
      }
    },
    // expand month in history of restaurant
    expandMonth(month) {
      let open = document.getElementById("open_month" + month);
      let box = document.getElementById("month-" + month);

      if (box.style.height == "auto") {
        box.style.height = "40px";

        open.style.transform = "rotate(0deg)";

        return false;
      } else {
        box.style.height = "auto";

        open.style.transform = "rotate(180deg)";

        this.closeOtherMonth(month);

        return true;
      }
    },
    closeOtherMonth(except_month) {
      for (let index = 1; index <= 12; index++) {
        if (index !== except_month) {
          let open = document.getElementById("open_month" + index);
          let box = document.getElementById("month-" + index);

          if (box.style.height == "auto") {
            box.style.height = "40px";

            open.style.transform = "rotate(0deg)";
          }
        }
      }
    },
    fetchCategories() {
      this.global_loading.show = true;

      this.$http
        .get(this.$hostname + "/categories", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.restaurant_categories.data = resp.data.menu;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    deleteCategory(category_id) {
      this.global_loading.show = true;
      this.$http
        .delete(
          this.$hostname + "/categories/" + category_id,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.$router.push("/menu");
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchItems(category_id) {
      this.global_loading.show = true;
      this.$http
        .get(
          this.$hostname + "/categories/" + category_id + "/items",
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.restaurant_category.title = resp.data.category.title;
          this.restaurant_category.description = resp.data.category.description;
          this.restaurant_category.index = resp.data.category.index;

          this.restaurant_category.items = resp.data.items;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    deleteItem(item_id, category_id) {
      this.global_loading.show = true;
      this.$http
        .delete(
          this.$hostname + "/items/" + item_id,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();

          this.$router.push("/category/dish/" + category_id);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    activeItem(item_id, category_id) {
      let data = {
        item_id: item_id,
      };
      this.global_loading.show = true;

      this.$http
        .patch(
          this.$hostname + "/activation-item",
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.fetchItems(category_id);
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchCalls() {
      // this.global_loading.show = true
      this.$http
        .get(this.$hostname + "/calls", this.restaurant_token.config)
        .then((resp) => {
          // this.successRequest()
          this.restaurant_calls.data = resp.data.calls;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    // fetch orders for restaurant:
    // type 1 = At restaurant
    // type 2 = Pick up
    // type 3 = Delivery
    fetchOrders() {
      this.global_loading.show = true;

      this.$http
        .get(this.$hostname + "/orders", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.restaurant_new_orders.data = [];
          this.restaurant_orders.data = [];

          resp.data.orders.forEach((order) => {
            order.progress_status
              ? this.restaurant_orders.data.push(order)
              : this.restaurant_new_orders.data.push(order);
          });
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchReservations() {
      // this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/reservations", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.restaurant_reservations.pending = resp.data.pendings;
          this.restaurant_reservations.confirm = resp.data.confirms;
        })
        .catch((err) => {
          this.successRequest();

          if (err.response.status == 403) {
            this.error_message = "Your reservation feature is not activated";
          }
        });
    },
    acceptOrder(order) {
      if (order.preferred_receive_at) {
        this.global_loading.show = true;
        let data = {
          order_id: order.id,
        };

        this.$http
          .patch(
            this.$hostname + "/accept-order",
            data,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();

            this.fetchOrders();
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        this.resetModal(order);
      }
    },
    sendOrderToNordantia(order) {
      if (localStorage.nordantia_delivery == 1) {
        window.location.replace(
          "https://wa.me/358451012959?text=Destination Address:" +
            order.address.description +
            " %0APhone Number:" +
            order.address.phone_number +
            " %0AOrder Number:" +
            order.id +
            " %0ARestaurant: " +
            order.restaurant.title +
            " - " +
            order.restaurant.address +
            " - " +
            order.restaurant.phone_number +
            " %0A>>>PRICE: " +
            this.formatPrice(this.totalPrice(order.json_items))
        );
      }
    },
    resetModal(order) {
      this.order_processing.data = order;
      this.showModal("timer_modal");
    },
    setDuration(duration) {
      this.global_loading.show = true;
      let data = {
        order_id: this.order_processing.data.id,
        duration: parseInt(duration),
        total: this.totalPrice(this.order_processing.data.json_items),
      };

      this.$http
        .patch(
          this.$hostname + "/set-duration",
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.closeModal("timer_modal");

          this.fetchOrders();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    deliverOrder(order) {
      this.global_loading.show = true;
      let data = {
        order_id: order.id,
      };

      this.$http
        .patch(
          this.$hostname + "/deliver-order",
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          if (order.payment_status == 0 && order.payment_method !== "online") {
            this.paidOrder(
              order.id,
              order.order_type,
              this.formatPrice(
                this.totalPrice(order.json_items) + order.delivery_charge
              ),
              order.payment_status
            );
          } else {
            this.successRequest();
            this.fetchOrders();
          }
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    paidOrder(order_id, order_type, total, payment_status) {
      this.global_loading.show = true;
      let data = {
        order_id: order_id,
        payment_status: payment_status,
        total: total,
      };

      this.$http
        .patch(
          this.$hostname + "/paid-order",
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.fetchOrders();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    setOrderType(order_type) {
      return order_type == "at_restaurant"
        ? 1
        : order_type == "pick_up"
        ? 2
        : 3;
    },
    setRedirectPath(path) {
      localStorage.redirect_to = path;
    },
    logout() {
      let data = {};
      this.$http
        .post(this.$hostname + "/logout", data, this.restaurant_token.config)
        .then((resp) => {
          this.$router.push("/login");
          window.location.reload(true);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    clearStorage() {
      localStorage.removeItem("total");
      localStorage.removeItem("comment");
      localStorage.removeItem("table");
      localStorage.removeItem("cart");
      localStorage.removeItem("qr");
      localStorage.removeItem("address_id");
      localStorage.removeItem("payment_type");
      localStorage.removeItem("order_type");
    },
    successRequest() {
      this.global_loading.show = false;
    },
    showErrors(errors) {
      console.log(errors);
      this.global_loading.show = false;
      if (
        errors.response.status == 401 ||
        errors.response.status == 403 ||
        errors.response.status == 405
      ) {
        this.setRedirectPath(this.$route.name);

        if (localStorage.restaurant_token) {
          localStorage.removeItem("restaurant_token");
          this.$router.push("/login");
        }

        this.$store.dispatch("login", {
          auth: false,
        });

        localStorage.removeItem("auth");
      }
      if (errors.response.data.errors) {
        // this.global_error.message = errors.response.data.errors[0]
        this.global_error.message = JSON.parse(
          JSON.stringify(errors.response.data.errors[0])
        );
      }
      if (errors.response.data.message) {
        if (errors.response.status == 401) {
          this.global_error.message = "User not logged in!";
        } else {
          this.global_error.message = JSON.parse(
            JSON.stringify(errors.response.data.message)
          );
        }
      }
      if (errors.response.data.error) {
        this.global_error.message = JSON.parse(
          JSON.stringify(errors.response.data.error)
        );
      }

      this.global_error.show = true;
    },
  },
});
