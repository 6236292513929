<template>
  <div id="delete_photo_modal" class="modal">
    <div class="question">
      <h3>{{ $t("account.are_sure_delete") }}?</h3>
      <img
        class="restaurant-photo"
        :src="url"
        alt=""
        height="100"
        width="100"
      />
      <button @click="closeModal('delete_photo_modal')" class="cancel">
        {{ $t("account.cancel") }}
      </button>
    </div>
    <button @click="done()" class="accept">
      {{ $t("account.delete") }}
    </button>
  </div>
</template>
<script>
export default {
  props: {
    title: "",
    photo_id: 0,
    index: 0,
    url: "",
  },
  methods: {
    done() {
      let data = {
        photo_id: this.photo_id,
      };

      this.global_loading.show = true;
      this.$http
        .post(
          this.$hostname + "/remove-photo",
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.$emit("deleted_photo", this.index);
          this.closeModal("delete_photo_modal");
        })
        .catch((err) => {
          this.closeModal("delete_photo_modal");

          this.showErrors(err);
        });
    },
  },
};
</script>
<style scoped>
.modal {
  height: 370px;
  gap: 12px;
  justify-content: flex-start;
}
.question {
  background-color: #ffffff;
  border-radius: 16px;
}
h3 {
  height: 64px;
  padding: 16px;
  border-bottom: 1px solid #f5f6f7;
}
button {
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  height: 48px;
}
button.cancel {
  border-radius: 16px;
  font-weight: 500;
  font-size: 18px;
}
button.accept {
  border-radius: 16px;
  color: #ff6a4d;
  font-size: 18px;
}
.restaurant-photo {
  object-fit: cover;
  width: 100%;
}
</style>