<template>
  <div class="info-page">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>
        <div class="title">{{ $t("home.manage_info") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <router-link class="btn info" to="/restaurant-info">
        <div class="left">
          <div class="icon"></div>
          <h4 class="caption">{{ $t("home.restaurant_info") }}</h4>
        </div>
        <div class="icon arrow"></div>
      </router-link>
      <router-link class="btn delivery" to="/delivery">
        <div class="left">
          <div class="icon"></div>
          <h4 class="caption">{{ $t("order.delivery") }}</h4>
        </div>
        <div class="icon arrow"></div>
      </router-link>
      <router-link class="btn services" to="/services">
        <div class="left">
          <div class="icon"></div>
          <h4 class="caption">{{ $t("home.services") }}</h4>
        </div>
        <div class="icon arrow"></div>
      </router-link>
      <router-link class="btn reservation" to="/reservation-info">
        <div class="left">
          <div class="icon"></div>
          <h4 class="caption">{{ $t("reserve.table_reserve") }}</h4>
        </div>
        <div class="icon arrow"></div>
      </router-link>
      <router-link class="btn social_links" to="/socials-links">
        <div class="left">
          <div class="icon"></div>
          <h4 class="caption">{{ $t("home.social_links") }}</h4>
        </div>
        <div class="icon arrow"></div>
      </router-link>
    </div>

    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import CallsModal from "../../components/restaurant/CallsModal.vue";
import CallWaiter from "../../components/restaurant/CallWaiter.vue";

export default {
  components: {
    BackButton,
    CallWaiter,
    CallsModal,
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}

.content {
  height: 100vh;
  overflow-y: scroll;
  padding: 78px 16px 96px;
  gap: 8px;
}

.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
}

.info .left .icon {
  background-image: url("../../assets/restaurant/home/info.svg");
}

.delivery .left .icon {
  background-image: url("../../assets/restaurant/home/delivery.svg");
}

.services .left .icon {
  background-image: url("../../assets/restaurant/home/services.svg");
}

.reservation .left .icon {
  background-image: url("../../assets/restaurant/navbar/reservation_on.svg");
}



.social_links .left .icon {
  background-image: url("../../assets/restaurant/home/social_links.svg");
}

.left .icon {
  background-color: #ffffff;
}
</style>