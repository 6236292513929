<template>
  <div class="modal" id="compulsories_modal">
    <div class="content">
      <div class="head">
        <button @click="close" class="icon close"></button>
        <div class="title">
          {{ compulsory.id ? compulsory.title : "Add compulsory" }}
        </div>
        <span @click="submit" class="done">Done</span>
      </div>
      <div class="body">
        <!-- title -->
        <div class="title-comp">
          <div class="input">
            <label class="deactive" for="title">{{
              $t("information.title")
            }}</label>
            <input
              class="active"
              type="text"
              id="title"
              v-model="compulsory.title"
              placeholder="i.g. Bread"
            />
          </div>
        </div>
        <div class="limit-select">
          <div class="label">Number of items user must select :</div>
          <div class="control">
            <button
              @click="decreaseLimit()"
              class="icon minus"
              :style="[
                compulsory.limit > 1 ? { opacity: '1' } : { opacity: '0.5' },
              ]"
            ></button>

            <span>{{ compulsory.limit }}</span>

            <button @click="increaseLimit()" class="icon plus"></button>
          </div>
        </div>
        <div
          v-for="(sub_compulsory, index) in compulsory.sub_compulsories"
          :key="index"
          class="option"
        >
          <div class="row">
            <h4>{{ "Option " + (index + 1) }}</h4>
            <button
              v-if="compulsory.sub_compulsories.length > 2"
              @click="deleteOption(index)"
              class="icon delete"
            ></button>
          </div>
          <div class="row">
            <!-- Title -->
            <div class="input title-option">
              <label class="deactive" :for="'title_' + index">{{
                $t("information.title")
              }}</label>
              <input
                class="active"
                type="text"
                :id="'title_' + index"
                v-model="sub_compulsory.title"
              />
            </div>
            <!-- price -->
            <div class="input price">
              <label class="deactive" :for="'price' + index">{{
                $t("history.price")
              }}</label>
              <input
                class="active"
                type="text"
                :id="'price' + index"
                v-model="sub_compulsory.price"
              />
              <span>€</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="compulsory.id" class="actions">
        <button @click="newOption" class="fill">
          {{ "Add another option" }}
        </button>
        <button @click="deleteCompulsory(compulsory)" class="outline">
          {{ "Delete" }}
        </button>
      </div>
      <button v-else @click="newOption" class="outline">
        {{ "Add another option" }}
      </button>
    </div>
    <delete-compulsory-modal
      @closed_delete="closedDelete"
      :item_id="item_id"
      :compulsory_id="selected_compulsory.id"
      :title="selected_compulsory.title"
    ></delete-compulsory-modal>
  </div>
</template>
<script>
import DeleteCompulsoryModal from "./DeleteCompulsoryModal.vue";
export default {
  components: { DeleteCompulsoryModal },
  methods: {
    submit() {
      if (this.compulsory.id) {
        this.updateCompulsory();
      } else {
        let data = {
          title: this.compulsory.title,
          limit: this.compulsory.limit,
          sub_compulsories: this.compulsory.sub_compulsories,
        };
        this.global_loading.show = true;
        this.$http
          .post(
            this.$hostname + `/items/${this.item_id}/compulsories`,
            data,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.close();
            this.$emit("closed", true);
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
    updateCompulsory() {
      let data = {
        title: this.compulsory.title,
        limit: this.compulsory.limit,
        sub_compulsories: this.compulsory.sub_compulsories,
      };
      this.global_loading.show = true;
      this.$http
        .patch(
          this.$hostname +
            `/items/${this.item_id}/compulsories/${this.compulsory.id}`,
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.close();
          this.$emit("closed", true);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    deleteCompulsory(compulsory) {
      this.selected_compulsory = compulsory;
      this.showModal("delete_compulsory_modal");
    },
    closedDelete(val) {
      if (val) {
        this.$emit("closed", true);
      }
    },
    newOption() {
      let new_option = {
        title: "",
        price: null,
      };

      this.compulsory.sub_compulsories.push(new_option);
    },
    close() {
      this.compulsory = {
        id: null,
        title: "",
        limit: 1,
        sub_compulsories: [
          {
            title: "",
            price: null,
          },
          {
            title: "",
            price: null,
          },
        ],
      };
      this.closeModal("compulsories_modal");
    },
    deleteOption(index) {
      if (this.compulsory.sub_compulsories.length > 2) {
        this.compulsory.sub_compulsories.splice(index, 1);
      }
    },
    increaseLimit() {
      this.compulsory.limit++;
      if (this.compulsory.limit > this.compulsory.sub_compulsories.length) {
        this.newOption();
      }
    },
    decreaseLimit() {
      if (this.compulsory.limit > 1) {
        this.compulsory.limit--;
      }
    },
  },
  data() {
    return {
      title: "",
      selected_compulsory: {},
    };
  },
  props: {
    item_id: 0,
    compulsory: {
      title: "",
      limit: 1,
      sub_compulsories: [
        {
          title: "",
          price: null,
        },
        {
          title: "",
          price: null,
        },
      ],
    },
  },
};
</script>
<style scoped>
.modal {
  padding: 0;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #4d4d4d;
  width: 100%;
  border-bottom: 1px solid #f0edf2;
  padding: 12px 0;
}

.content {
  background-color: #ffffff;
  height: 100vh;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  height: 70vh;
  width: 100%;
  overflow-y: scroll;
}
.title-comp {
  width: 100%;
  border-bottom: 1px solid #f0edf2;
  padding-bottom: 16px;
}
.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
}
.done {
  font-weight: 400;
  font-size: 16px;
  color: #5a187a;
}
.close {
  background-image: url("../../assets/common/close.svg");
  height: 28px;
  width: 28px;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.option {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.row .title-option {
  width: 64%;
}
.row .price {
  width: 35%;
}
.row input {
  width: 49%;
}
h4 {
  font-weight: 400;
  font-size: 18px;
  color: #737373;
}
.actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.actions .outline {
  width: 30%;
  border: 1px solid #f0edf2;
}
.fill {
  width: 68%;
  background-color: #5a187a;
  border: 1px solid #5a187a;
}
.outline {
  width: 100%;
  font-weight: 400;
  border: 1px solid #5a187a;
  color: #5a187a;
}

.limit-select {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.limit-select .label {
  font-weight: 400;
  font-size: 16px;
  color: #737373;
}

.control {
  display: flex;
  min-width: 80px;
  width: fit-content;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  color: #776282;
}

.control .icon {
  width: 24px;
  height: 24px;
  background-size: 20px;
  background-color: #faf5ff;
  border-radius: 8px;
}

.plus {
  background-image: url("../../assets/restaurant/home/plus_comp.svg");
}

.minus {
  background-image: url("../../assets/restaurant/home/minus_comp.svg");
}
</style>