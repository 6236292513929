<template>
  <div id="delete_table_modal" class="modal">
    <div class="question">
      <h3>{{ $t("account.are_sure_delete") }} “{{ table.name }}”?</h3>
      <button @click="closeModal('delete_table_modal')" class="cancel">
        {{ $t("account.cancel") }}
      </button>
    </div>
    <button @click="deleteTable()" class="accept">
      {{ $t("account.delete") }}
    </button>
  </div>
</template>
<script>
export default {
  props: {
    table: {},
  },
  methods: {
    deleteTable() {
      console.log("hey");
      this.$http
        .delete(
          this.$hostname + "/tables/" + this.table.id,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.closeModal("delete_table_modal");
          this.$emit("closed_delete_model", true);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
};
</script>
<style scoped>
.modal {
  height: 280px;
  gap: 12px;
  justify-content: flex-start;
}
.question {
  background-color: #ffffff;
  border-radius: 16px;
}
h3 {
  height: 64px;
  padding: 16px;
  border-bottom: 1px solid #f5f6f7;
}
button {
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  height: 48px;
}
button.cancel {
  border-radius: 16px;
  font-weight: 500;
  font-size: 18px;
}
button.accept {
  border-radius: 16px;
  color: #ff6a4d;
  font-size: 18px;
}
</style>
