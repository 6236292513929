<template>
  <div id="category_modal" class="modal">
    <div class="question">
      <div class="input">
        <label class="deactive" for="title">{{ $t("information.name") }}</label>
        <input
          class="active"
          type="text"
          id="title"
          v-model="title"
          placeholder="i.g. Hamburger"
          v-on:keyup.enter="addNewCategory()"
        />
      </div>
      <div class="input">
        <label class="deactive" for="description">{{ $t("restaurant_menu.description") }}</label>
        <input
          class="active"
          type="text"
          id="description"
          v-model="description"
          v-on:keyup.enter="addNewCategory()"
        />
      </div>
      <!-- type of category -->
      <div class="input">
        <label class="deactive" for="category">{{ "Category type" }}</label>
        <select name="type" v-model="type" id="type">
          <option value="dish">
            {{ "Dish" }}
          </option>
          <option value="extra">
            {{ "Extra" }}
          </option>
        </select>
      </div>
      <button @click="addNewCategory" class="fill">{{ $t("order.submit") }}</button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    addNewCategory() {
      if (this.title.length) {
        this.global_loading.show = true;
        let data = {
          title: this.title,
          description: this.description,
          type: this.type,
        };

        this.$http
          .post(
            this.$hostname + "/categories",
            data,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.fetchCategories();
            this.closeModal("category_modal");
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        this.global_error.show = true;
        this.global_error.message = "The title field is required!";
      }
    },
  },
  data() {
    return {
      title: "",
      type: "dish",
      description:""
    };
  },
};
</script>
<style scoped>
.modal {
  height: 260px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
select {
  width: 100%;
  background-image: url("../../assets/common/select.svg");
  background-position-x: right;
  background-position-y: center;
}

.fill {
  background-color: #5a187a;
  border: 1px solid #5a187a;
  max-width: 768px;
  width: 100%;
}
</style>