import Vue from "vue";
import store from "../store";
import myData from "../mainData";
import VueRouter from "vue-router";

import NotFound from "../views/NotFound.vue";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Orders from "../views/Orders.vue";
import Reservation from "../views/Reservation.vue";
import ReservationCreate from "../views/reservation/Create.vue";
import ReservationHistory from "../views/reservation/History.vue";
import Tables from "../views/reservation/Tables.vue";
import TableCombinations from "../views/reservation/TableCombinations.vue";
import InvalidTimes from "../views/reservation/InvalidTimes.vue";
import Settings from "../views/Settings.vue";
import Profile from "../views/Profile.vue";
import Menu from "../views/home/Menu.vue";
import History from "../views/home/History.vue";
import Info from "../views/home/Info.vue";
import Delivery from "../views/home/info/Delivery.vue";
import RestaurantInfo from "../views/home/info/RestaurantInfo.vue";
import ReservationInfo from "../views/home/info/Reservation.vue";
import ReservationSettings from "../views/reservation/Settings.vue";
import Services from "../views/home/info/Services.vue";
import SocialsLinks from "../views/home/info/SocialsLinks.vue";
import Rate from "../views/home/Rate.vue";
import Voucher from "../views/home/Voucher.vue";
import Category from "../views/home/Category.vue";
import Item from "../views/home/Item.vue";
import ExtraDrink from "../views/home/ExtraDrink.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
  },
  {
    path: "/reservation",
    name: "Reservation",
    component: Reservation,
  },
  {
    path: "/create-reservation",
    name: "ReservationCreate",
    component: ReservationCreate,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/table-combinations",
    name: "TableCombinations",
    component: TableCombinations,
  },
  {
    path: "/invalid-times",
    name: "InvalidTimes",
    component: InvalidTimes,
  },
  {
    path: "/reservation-history",
    name: "ReservationHistory",
    component: ReservationHistory,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/menu",
    name: "Menu",
    component: Menu,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/info",
    name: "Info",
    component: Info,
  },
  {
    path: "/delivery",
    name: "Delivery",
    component: Delivery,
  },
  {
    path: "/restaurant-info",
    name: "RestaurantInfo",
    component: RestaurantInfo,
  },
  {
    path: "/reservation-info",
    name: "ReservationInfo",
    component: ReservationInfo,
  },
  {
    path: "/reservation-settings",
    name: "ReservationSettings",
    component: ReservationSettings,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/socials-links",
    name: "SocialsLinks",
    component: SocialsLinks,
  },
  {
    path: "/rate",
    name: "Rate",
    component: Rate,
  },
  {
    path: "/voucher",
    name: "Voucher",
    component: Voucher,
  },
  {
    path: "/category/:type/:id",
    name: "Category",
    component: Category,
    props: true,
  },
  {
    path: "/item/:category_id/:id",
    name: "Item",
    component: Item,
    props: true,
  },
  {
    path: "/extra-drink/:type/:id",
    name: "ExtraDrink",
    component: ExtraDrink,
    props: true,
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Navigation Guard for restaurant side
router.beforeEach((to, from, next) => {
  if (
    [
      "Home",
      "Orders",
      "Reservation",
      "Settings",
      "Profile",
      "Menu",
      "History",
      "Info",
      "Rate",
      "Category",
      "ExtraDrink",
    ].includes(to.name) &&
    !localStorage.restaurant_token
  )
    next({ name: "Login" });
  else next();
});

router.beforeEach((to, from, next) => {
  if (["Login"].includes(to.name) && localStorage.restaurant_token)
    next({ name: "Orders" });
  else next();
});

export default router;
