export const LOGIN = (state, { auth }) => {
  state.auth = auth
}

export const SET_NAME = (state, { name }) => {
  state.name = name
}

export const SET_EMAIL = (state, { email }) => {
  state.email = email
}

export const SET_TOKEN = (state, { token }) => {
  state.token = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }
}

// just for restaurant
export const SET_USER_ID = (state, { user }) => {
  state.user = user
}
