<template>
  <div class="reservation">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/reservation-info'"></back-button>
        <div class="title">{{ $t("home.settings") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <v-text-field
        v-model="default_reservation_length"
        filled
        suffix="min"
        :label="$t('reserve.default_length')"
      ></v-text-field>

      <v-text-field
        v-model="step_reservation"
        filled
        suffix="min"
        :label="'Step'"
      ></v-text-field>

      <v-textarea
        v-model="reservation_note"
        filled
        rows="4"
        :label="$t('reserve.reservation_note')"
        maxlength="1000"
      ></v-textarea>

      <div class="hours">
        <button @click="showModal('reservation_hours_modal')" class="light">
          {{ $t("reserve.times") }}
        </button>
      </div>

      <button @click="done()" class="fill">{{ $t("information.save") }}</button>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
      <ReservationHoursModal
        :reservation_times="reservation_times"
        @reservation_times="reservationTimes"
      ></ReservationHoursModal>
    </div>
  </div>
</template>
<script>
import CallsModal from "../../components/restaurant/CallsModal.vue";
import CallWaiter from "../../components/restaurant/CallWaiter.vue";
import BackButton from "../../components/common/BackButton.vue";
import ReservationHoursModal from "../../components/restaurant/ReservationHoursModal.vue";

export default {
  components: {
    CallWaiter,
    CallsModal,
    BackButton,
    ReservationHoursModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/info", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();

          console.log(resp.data.info[0].opening_times);
          this.reservation_times = resp.data.info[0].reservation_times.length
            ? resp.data.info[0].reservation_times
            : resp.data.info[0].opening_times;

          this.reservation_note = resp.data.info[0].reservation_note;
          this.step_reservation = resp.data.info[0].step_reservation;

          this.default_reservation_length =
            resp.data.info[0].default_reservation_length;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    reservationTimes(val) {
      this.reservation_times = val;
      this.save();
    },
    save() {
      let fd = new FormData();

      fd.append("reservation_times", JSON.stringify(this.reservation_times));

      fd.append(
        "reservation_note",
        this.reservation_note ? this.reservation_note : ""
      );
      fd.append(
        "default_reservation_length",
        this.default_reservation_length ? this.default_reservation_length : 1
      );

      fd.append(
        "step_reservation",
        this.step_reservation ? this.step_reservation : 15
      );

      console.log(fd);

      this.global_loading.show = true;
      this.$http
        .post(
          this.$hostname + "/reservation-info",
          fd,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    done() {
      this.save();
      this.backToHome();
    },
    backToHome() {
      this.$router.push("/reservation-info");
    },
  },
  data() {
    return {
      reservation_times: [],
      reservation_note: "",
      default_reservation_length: 1,
      step_reservation: 15,
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: auto;
  overflow-y: scroll;
  gap: 8px;
}
.btn {
  padding: 12px 8px;
  font-size: 14px;
  flex-direction: column;
  gap: 12px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 6px;
}

.options .btn {
  width: 49%;
  justify-content: center;
}

.fill {
  width: 100%;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  height: 48px;
  color: #ffffff;
  background-color: #412250;
  border: 1px solid #412250;
}

.head,
.description {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.edit {
  background-image: url("../../assets/restaurant/home/pen_gray.svg");
}

.delete {
  background-image: url("../../assets/restaurant/home/trash_gray.svg");
}

.btn {
  padding: 12px 8px;
  font-size: 14px;
  flex-direction: column;
  gap: 12px;
  color: #4d4d4d;
}

.hours {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #f0edf2;
  margin-bottom: 12px;
}
.hours button {
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
  height: 42px;
}
</style>
