<template>
  <div id="logout_modal" class="modal">
    <div class="question">
      <h3>{{ $t("settings.sure_logout") }}</h3>
      <button @click="closeModal('logout_modal')" class="cancel">
        {{ $t("account.cancel") }}
      </button>
    </div>
    <button @click="logout" class="accept">{{ $t("settings.logout") }}</button>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.modal {
  height: 260px;
  gap: 12px;
  justify-content: flex-start;
}
.question {
  background-color: #ffffff;
  border-radius: 16px;
}
h3 {
  height: 53px;
  padding: 16px;
  border-bottom: 1px solid #f5f6f7;
}
button {
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  height: 48px;
}
button.cancel {
  border-radius: 16px;
  font-weight: 500;
  font-size: 18px;
}
button.accept {
  border-radius: 16px;
  color: #ff6a4d;
  font-size: 18px;
}
</style>