<template>
  <div class="btn">
    <div @click="showLanguages" class="head">
      <div class="left">
        <div class="icon"></div>
        <h4>
          {{ $t("language") }}
        </h4>
      </div>
      <div id="open_languages" class="expand"></div>
    </div>
    <div id="languages" class="body">
      <div class="options">
        <div
          :class="[$i18n.locale == 'en' ? 'active' : '', 'option']"
          @click="changeLanguage('en')"
        >
          <label for="english">
            {{ "English" }}
          </label>
          <input
            class="language"
            type="radio"
            :checked="$i18n.locale == 'en'"
            id="english"
            name="language"
          />
        </div>

        <div
          :class="[$i18n.locale == 'fi' ? 'active' : '', 'option']"
          @click="changeLanguage('fi')"
        >
          <label for="finish">
            {{ "Suomi" }}
          </label>
          <input
            class="language"
            type="radio"
            :checked="$i18n.locale == 'fi'"
            id="finish"
            name="language"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.language = lang;
    },
  },
  data() {
    return {
      language: "",
      show_languages: false,
    };
  },
};
</script>
<style scoped>
#languages {
  display: none;
  transition: 0.3s ease;
  height: 0;
  width: 100%;
}

.btn {
  transition: 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #f5f6f7;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
}

.head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  transition: 0.3s ease;
}
.left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  gap: 8px;
}

.left .icon {
  width: 40px;
  height: 40px;
  background-color: #edefff;
  border-radius: 8px;
  background-image: url("../../assets/common/translate.svg");
}

.expand {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  width: 24px;
  transition: 0.3s ease;
  background-image: url("../../assets/common/down.svg");
}

.options {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
}

.options input {
  appearance: none;
  outline: none;
}
.option {
  border: 1px solid #8f93bf;
  border-radius: 8px;
  width: 50%;
  height: 40px;
  color: #4c54b3;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  font-size: 16px;
}
.active {
  color: #f5f6f7;
  background-color: #4c54b3;
}
</style>