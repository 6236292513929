<template>
  <div class="orders">
    <div class="header">
      <div class="container">
        <button class="app-btn" @click="openMenu()">
          <div class="app icon"></div>
          <div id="customer_app">{{ "Walk-in order" }}</div>
        </button>

        <div class="title">{{ $t("order.orders") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <div class="orders">
        <order-card
          v-for="(order, index) in displayOrders"
          :key="index"
          :order="order"
        ></order-card>
        <empty-history v-if="displayOrders.length == 0"></empty-history>
      </div>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
      <set-timer-modal></set-timer-modal>
      <reject-modal></reject-modal>
    </div>
  </div>
</template>
<script>
import CallsModal from "../components/restaurant/CallsModal.vue";
import CallWaiter from "../components/restaurant/CallWaiter.vue";
import OrderCard from "../components/restaurant/OrderCard.vue";
import SetTimerModal from "../components/restaurant/SetTimerModal.vue";
import RejectModal from "../components/restaurant/RejectModal.vue";
import EmptyHistory from "../components/restaurant/EmptyHistory.vue";

export default {
  components: {
    CallWaiter,
    CallsModal,
    OrderCard,
    SetTimerModal,
    RejectModal,
    EmptyHistory,
  },
  mounted() {
    this.getNewUpdate();
    this.fetchMenu();
    this.fetchOrders();
    Pusher.logToConsole = false;

    const vm = this;
    let order_channel = this.$pusher.subscribe(
      "orders-" + this.decodeUserIdByToken()
    );

    order_channel.bind("orders", function (data) {
      vm.fetchOrders();
    });

    order_channel.connection.bind("disconnected", () => {
      // Logic to handle disconnection, e.g., retry connection
      setTimeout(() => {
        order_channel.connect();
      }, 5000); // Retry connection after 5 seconds
    });

    
  },
  methods: {
    openMenu() {
      window.location.href = this.menu;
      //  this.$router.push(this.$customer_app);
    },
    fetchMenu() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/profile", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();

          this.menu =
            this.$customer_app +
            "/menu/" +
            resp.data.profile.restaurant[0].menu.qr_url;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  computed: {
    displayOrders() {
      return this.restaurant_new_orders.data.concat(
        this.restaurant_orders.data
      );
    },
  },
  data() {
    return {
      menu: "",
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 100vh;
  /* overflow-y: scroll; */
  padding-top: 75px;
  gap: 8px;
  padding-right: 0;
  padding-left: 0;
}
.orders {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px;
  padding-bottom: 80px;
}

.app {
  left: 8px;
  top: 8px;
  position: absolute;
  background-image: url("../assets/restaurant/home/menu.svg");
  width: 22px;
  height: 22px;
  background-size: 22px;
}

.app-btn {
  position: relative;
  background-color: #f5f6f7;
  border-radius: 8px;
  min-height: 40px;
  width: 128px;
  font-size: 14px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  display: flex;
  color: #412250;
  padding-right: 8px;
}
</style>
