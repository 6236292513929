<template>
  <div id="delete_category_modal" class="modal">
    <div class="question">
      <h3>{{ $t("account.are_sure_delete") }} “{{ title }}”?</h3>
      <button @click="cancel()" class="cancel">
        {{ $t("account.cancel") }}
      </button>
    </div>
    <button @click="deleteCategory(id)" class="accept">
      {{ $t("account.delete") }}
    </button>
  </div>
</template>
<script>
export default {
  methods: {
    cancel() {
      this.closeModal("item_modal");
      this.closeModal("delete_category_modal");
    },
  },
  props: {
    title: "",
    id: 0,
  },
};
</script>
<style scoped>
.modal {
  height: 280px;
  gap: 12px;
  justify-content: flex-start;
}
.question {
  background-color: #ffffff;
  border-radius: 16px;
}
h3 {
  height: 64px;
  padding: 16px;
  border-bottom: 1px solid #f5f6f7;
}
button {
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  height: 48px;
}
button.cancel {
  border-radius: 16px;
  font-weight: 500;
  font-size: 18px;
}
button.accept {
  border-radius: 16px;
  color: #ff6a4d;
  font-size: 18px;
}
</style>