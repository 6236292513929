<template>
  <div class="item">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/category/dish/' + category_id"></back-button>
        <div class="title">
          {{ edit ? item.title : $t("restaurant_menu.add_new_item") }}
        </div>
        <button
          @click="showModal('delete_item_modal')"
          class="icon delete"
        ></button>
      </div>
    </div>
    <div class="content">
      <div class="body">
        <!-- photo -->
        <div
          class="photo_input"
          :style="[
            url
              ? { 'background-image': 'url(' + url + ')' }
              : { 'background-image': 'url(' + item.photo + ')' },
          ]"
        >
          <label
            v-if="item.photo"
            for="photo_fill"
            @click.prevent="deletePhoto()"
          ></label>
          <label v-else for="photo" id="upload"></label>

          <div
            @click.prevent="deletePhoto()"
            class="trash"
            v-if="item.photo"
          ></div>
          <span v-else>{{ $t("restaurant_menu.food_pic") }}</span>

          <input @input="setPhoto" type="file" name="photo" id="photo" />
        </div>

        <!-- title -->
        <div class="input">
          <label class="deactive" for="title">{{
            $t("information.name")
          }}</label>
          <input
            @change="submitAfterRename"
            class="active"
            type="text"
            id="title"
            v-model="item.title"
            placeholder="i.g. cheese (L/V/G)"
          />
        </div>
        <div class="input">
          <label class="deactive" for="title">{{ "Index" }}</label>

          <input
            @change="submitAfterRename"
            class="active"
            type="text"
            id="index"
            v-model="item.index"
            placeholder="Index"
          />
        </div>

        <!-- category of item -->
        <div class="input">
          <label class="deactive" for="category">{{
            $t("restaurant_menu.category")
          }}</label>
          <select name="category" v-model="selected_category" id="category">
            <option
              v-for="category in restaurant_categories.data"
              :key="category.id"
              :value="category.id"
            >
              {{ category.title }}
            </option>
          </select>
        </div>

        <!-- Suomi Description -->
        <div class="input">
          <label class="deactive desc" for="desc-fi">{{
            $t("restaurant_menu.description") + " (Suomi)"
          }}</label>
          <textarea
            @change="saveOnChange"
            class="active"
            type="text"
            id="desc-fi"
            v-model="item.description_fi"
          ></textarea>
        </div>

        <!-- English Description -->
        <div class="input">
          <label class="deactive desc" for="desc-en">{{
            $t("restaurant_menu.description") + " (English)"
          }}</label>
          <textarea
            @change="saveOnChange"
            class="active"
            type="text"
            id="desc-en"
            v-model="item.description_en"
          ></textarea>
        </div>

        <div class="row">
          <!-- price -->
          <div class="input">
            <label class="deactive" for="price">{{
              $t("history.price")
            }}</label>
            <input
              @change="saveOnChange"
              class="active"
              type="text"
              id="price"
              v-model="item.price"
            />
            <span>€</span>
          </div>

          <!-- Discount -->
          <div class="input">
            <label class="deactive" for="discount">{{
              $t("restaurant_menu.discount")
            }}</label>
            <input
              @change="saveOnChange"
              class="active"
              type="text"
              id="discount"
              v-model="item.discount"
            />
            <span>%</span>
          </div>
        </div>

        <!-- tax -->
        <div class="row tax-row">
          <div class="tax">
            <input v-model="item.tax" type="checkbox" name="tax" id="tax" />
            <label for="tax">
              {{ "This item includes alcoholic beverages (24% VAT)" }}
            </label>
          </div>

          <div class="tax-note">
            {{ "*The default VAT rate is 14% for all items." }}
          </div>
        </div>

        <button @click="editCompulsory(empty_compulsory)" class="outline">
          Add compulsories
        </button>

        <div class="compulsories">
          <div
            class="compulsory"
            v-for="(compulsory, index) in item.compulsories"
            :key="index"
            @click="editCompulsory(compulsory)"
          >
            {{ compulsory.title }}
          </div>
        </div>

        <h4>
          <div class="icon tip"></div>
          {{ $t("restaurant_menu.food_allergens") }}
        </h4>

        <!-- allergens -->
        <div class="options">
          <!-- Vegan -->
          <input
            type="checkbox"
            name="allergens"
            id="vegan"
            v-model="item.vegan"
            :checked="item.vegan"
          />
          <label for="vegan">{{ $t("restaurant_menu.vegan") }}</label>

          <!-- Gluten -->
          <input
            type="checkbox"
            name="allergens"
            id="gluten"
            v-model="item.gluten"
            :checked="item.gluten"
          />
          <label for="gluten">{{ $t("restaurant_menu.gluten") }}</label>

          <!-- Lactose -->
          <input
            type="checkbox"
            name="allergens"
            id="lactose"
            v-model="item.lactose"
            :checked="item.lactose"
          />
          <label for="lactose">{{ $t("restaurant_menu.lactose") }}</label>
        </div>

        <!-- Extras -->
        <h4>
          <div class="icon tip"></div>
          {{ $t("cart.extras") }}
        </h4>

        <div class="options">
          <div
            class="option extra-cat"
            v-for="extra in extras"
            :key="extra.id"
            @click="selectExtraCategory(extra)"
          >
            {{ extra.title }}
          </div>
          <!-- <div
            class="option"
            v-for="extra in extras"
            :key="extra.id"
            @click.prevent="setExtras(extra.id)"
          >
            <input
              type="checkbox"
              name="extras"
              :id="extra.title + extra.id"
              :checked="checked_extras.includes(extra.id)"
            />
            <label :for="extra.title + extra.id">{{
              extra.title + " " + formatPrice(extra.price)
            }}</label>
          </div> -->
        </div>

        <!-- food info -->
        <h4 class="food_info" @click="expandToggle()">
          <div class="icon tip"></div>
          {{ $t("restaurant_menu.food_info") }}
        </h4>

        <div v-if="food_info" class="row">
          <!-- Energy -->
          <div class="input">
            <label class="deactive" for="energy">{{
              $t("restaurant_menu.energy")
            }}</label>
            <input
              class="active"
              type="text"
              id="energy"
              v-model="item.energy"
            />
            <span>Kcal</span>
          </div>

          <!-- Protein -->
          <div class="input">
            <label class="deactive" for="protein">{{
              $t("restaurant_menu.protein")
            }}</label>
            <input
              class="active"
              type="text"
              id="protein"
              v-model="item.protein"
            />
            <span>g</span>
          </div>
        </div>

        <div v-if="food_info" class="row">
          <!-- Fat -->
          <div class="input">
            <label class="deactive" for="fat">{{
              $t("restaurant_menu.fat")
            }}</label>
            <input class="active" type="text" id="fat" v-model="item.fat" />
            <span>g</span>
          </div>

          <!-- Carbs -->
          <div class="input">
            <label class="deactive" for="carbs">{{
              $t("restaurant_menu.carbohyd")
            }}</label>
            <input
              class="active"
              type="text"
              id="carbs"
              v-model="item.carbohydrate"
            />
            <span>g</span>
          </div>
        </div>

        <div v-if="food_info" class="row">
          <!-- Fiber -->
          <div class="input">
            <label class="deactive" for="fiber">{{
              $t("restaurant_menu.fiber")
            }}</label>
            <input class="active" type="text" id="fiber" v-model="item.fiber" />
            <span>g</span>
          </div>

          <!-- Salt -->
          <div class="input">
            <label class="deactive" for="salt">{{
              $t("restaurant_menu.salt")
            }}</label>
            <input class="active" type="text" id="salt" v-model="item.salt" />
            <span>g</span>
          </div>
        </div>
      </div>

      <div class="popular">
        <input
          v-model="item.is_pop"
          type="checkbox"
          name="popular"
          id="popular"
        />
        <label for="popular">
          {{ $t("restaurant_menu.popular_discount") }}
        </label>
      </div>

      <button v-if="edit" @click="saveItem" class="fill">
        {{ $t("info.save_changes") }}
      </button>

      <button v-else @click="addNewItem" class="fill">
        {{ $t("restaurant_menu.add_item") }}
      </button>
    </div>
    <div id="blur_background" class="blur-bg">
      <delete-item-modal
        :title="item.title"
        :id="item.id"
        :category_id="$route.params.category_id"
      ></delete-item-modal>
      <compulsories-modal
        :item_id="item.id"
        :compulsory="selected_compulsory"
        @closed="closed"
      ></compulsories-modal>
      <extras-modal
        :extra_category="selected_extra_category"
        :checked_extras="checked_extras"
        @passExtras="setExtras"
      ></extras-modal>
    </div>
  </div>
</template>
<script>
import DeleteItemModal from "../../components/restaurant/DeleteItemModal.vue";
import BackButton from "../../components/common/BackButton.vue";
import CompulsoriesModal from "../../components/restaurant/CompulsoriesModal.vue";
import ExtrasModal from "../../components/restaurant/ExtrasModal.vue";

export default {
  mounted() {
    this.fetchExtras();
    this.setEditing();

    this.checked_extras = [];

    this.iOSBottomGapFixer(".content");

    let isSafari =
      navigator.vendor.match(/apple/i) &&
      !navigator.userAgent.match(/crios/i) &&
      !navigator.userAgent.match(/fxios/i) &&
      !navigator.userAgent.match(/Opera|OPT\//);

    let container = document.querySelector(".body");

    if (isSafari) {
      // Safari browser is used
      container.style.height = "62vh";
    } else {
      // Other browser is used
    }
  },
  components: {
    DeleteItemModal,
    BackButton,
    CompulsoriesModal,
    ExtrasModal,
  },
  methods: {
    expandToggle() {
      this.food_info = !this.food_info;
    },
    async fetchExtras() {
      this.global_loading.show = true;
      await this.$http
        .get(this.$hostname + "/extras", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();

          this.extras = resp.data.items.reverse();

          this.selected_category = this.$route.params.category_id;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    async fetch() {
      await this.$http
        .get(this.$hostname + "/items/" + this.id, this.restaurant_token.config)
        .then((resp) => {
          this.item = resp.data.item[0];
          if (resp.data.item[0].description_en) {
            this.item.description_en = resp.data.item[0].description_en.replace(
              /<br\s*[\/]?>/gi,
              "\n"
            );
          }
          if (resp.data.item[0].description_fi) {
            this.item.description_fi = resp.data.item[0].description_fi.replace(
              /<br\s*[\/]?>/gi,
              "\n"
            );
          }

          this.item.price = this.replaceDot(resp.data.item[0].price);

          resp.data.item[0].extras.forEach((extra) => {
            this.checked_extras.push(extra.pivot.extra_id);
          });

          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    setPhoto(e) {
      this.item.photo = e.target.files[0] || e.dataTransfer.files[0];
      this.url = URL.createObjectURL(this.item.photo);
    },
    deletePhoto() {
      if (this.item.photo) {
        this.item.photo = "";
      }
      if (this.url) {
        this.url = "";
      }
    },
    addNewItem() {
      if (this.item.title) {
        this.global_loading.show = true;
        this.$http
          .post(
            this.$hostname + "/categories/" + this.category_id + "/items",
            this.getFormData(),
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.$router.push("/category/dish/" + this.category_id);
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        this.global_error.show = true;
        this.global_error.message = "The title field is required!";
      }
    },
    saveItem() {
      this.global_loading.show = true;
      this.$http
        .post(
          this.$hostname + "/items/" + this.item.id,
          this.getFormData(),
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.$router.push("/category/dish/" + this.category_id);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    getFormData() {
      let fd = new FormData();

      fd.append("photo", this.item.photo);

      fd.append("title", this.item.title);
      fd.append("index", this.item.index);

      fd.append(
        "description_en",
        this.item.description_en
          ? this.item.description_en.replace(/\n/g, "<br />")
          : ""
      );

      fd.append(
        "description_fi",
        this.item.description_fi
          ? this.item.description_fi.replace(/\n/g, "<br />")
          : ""
      );

      fd.append("price", this.replaceComma(this.item.price));

      fd.append("tax", this.item.tax ? 24 : 14);

      fd.append("extras", JSON.stringify(this.checked_extras));

      fd.append("discount", this.item.discount);

      fd.append("energy", this.item.energy);

      fd.append("fat", this.item.fat);

      fd.append("carbohydrate", this.item.carbohydrate);

      fd.append("protein", this.item.protein);

      fd.append("salt", this.item.salt);

      fd.append("fiber", this.item.fiber);

      fd.append("gluten", this.item.gluten ? 1 : 0);

      fd.append("lactose", this.item.lactose ? 1 : 0);

      fd.append("vegan", this.item.vegan ? 1 : 0);

      fd.append("popular", this.item.is_pop ? 1 : 0);

      fd.append("category_id", parseInt(this.selected_category));

      return fd;
    },
    setExtras(extras) {
      console.log(extras);
      // const index = this.checked_extras.indexOf(extra);
      // if (index > -1) {
      //   this.checked_extras.splice(index, 1);
      // } else {
      //   this.checked_extras.push(extra);
      // }
    },
    editCompulsory(compulsory) {
      this.selected_compulsory = Object.assign({}, compulsory);

      this.showModal("compulsories_modal");
    },
    selectExtraCategory(extra_category) {
      this.selected_extra_category = Object.assign({}, extra_category);

      this.showModal("extras_category_modal");
    },
    submitAfterRename() {
      if (this.item.id == 0) {
        if (this.item.title) {
          this.$http
            .post(
              this.$hostname + "/categories/" + this.category_id + "/items",
              this.getFormData(),
              this.restaurant_token.config
            )
            .then((resp) => {
              this.item.id = resp.data.item.id;
              this.id = resp.data.item.id;

              this.$router.push({
                name: "Item",
                params: {
                  category_id: this.category_id,
                  id: resp.data.item.id,
                },
              });
              this.item.title = resp.data.item.title;

              this.setEditing();
            })
            .catch((err) => {
              this.showErrors(err);
            });
        } else {
          this.global_error.show = true;
          this.global_error.message = "The title field is required!";
        }
      }
    },
    saveOnChange() {
      this.$http
        .post(
          this.$hostname + "/items/" + this.item.id,
          this.getFormData(),
          this.restaurant_token.config
        )
        .then((resp) => {})
        .catch((err) => {});
    },
    closed(val) {
      if (val) {
        this.fetch();
      }
      val = false;
    },
    setEditing() {
      if (this.id == "undefined") {
        this.edit = false;
      } else {
        this.edit = true;
        this.fetch();
      }
    },
  },
  data() {
    return {
      food_info: false,
      url: "",
      selected_category: {},
      selected_compulsory: {},
      selected_extra_category: {},
      empty_compulsory: {
        title: "",
        limit: 1,
        sub_compulsories: [
          {
            title: "",
            price: null,
          },
          {
            title: "",
            price: null,
          },
        ],
      },
      item: {
        id: 0,
        title: "",
        index: 1,
        photo: "",
        description_fi: "",
        description_en: "",
        price: 0,
        discount: 0,
        energy: 0,
        protein: 0,
        fat: 0,
        carbohydrate: 0,
        fiber: 0,
        salt: 0,
      },
      checked_extras: [],
      extras: [],
      edit: false,
    };
  },
  props: {
    category_id: 0,
    id: 0,
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.item {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.content {
  max-height: 100%;
  height: 100% !important;
  min-height: 100%;
  padding: 65px 16px 0px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  height: calc(100% - 120px);
  width: 100%;
  padding-bottom: 200px;
}

.title {
  font-size: 22px;
  color: #412250;
}

.photo_input {
  width: 100%;
  min-height: 160px;
  position: relative;
}

.fill {
  background-color: #5a187a;
  border: 1px solid #5a187a;
  max-width: 768px;
  width: 100%;
  position: fixed;
  bottom: 68px;
}

select {
  width: 100%;
  background-image: url("../../assets/common/select.svg");
  background-position-x: right;
  background-position-y: center;
}
textarea {
  width: 70%;
  height: 100px;
}

.desc {
  width: 30%;
}

.compulsories {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 6px;
}

.extra-cat,
.compulsory {
  width: 49%;
  border: 1px solid #8f93bf3f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.row .input {
  width: 49%;
}

.row input {
  width: 49%;
}

h4 {
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid #f0edf2;
  justify-content: flex-start;
  padding-top: 8px;
  color: #737373;
}

.options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.option {
  width: 48.5%;
}

.options label {
  background-color: #f8f9fa;
  border-radius: 8px;
  width: 31%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #4d4d4d;
}

.option label {
  width: 100%;
}

.options input[type="checkbox"]:checked + label {
  border: 1px solid #5a187a;
}

.options input[type="checkbox"] {
  appearance: none;
  display: none;
  background-image: none;
}

.popular {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: flex-start;
  position: fixed;
  bottom: 116px;
  background-color: #ffffff;
  height: 28px;
}

.outline {
  font-weight: 400;
  min-height: 48px;
  width: 100%;
  color: #5a187a;
  border: 1px solid #5a187a;
}

.tax-row {
  flex-direction: column;
  align-items: flex-start;
}

.tax {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.tax input {
  width: 24px;
}

.tax-note {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #b3b3b3;
}

.popular label,
.tax label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #737373;
}

.tip {
  background-image: url("../../assets/common/tip_purple.svg");
  width: 24px;
}

.trash {
  background-image: url("../../assets/restaurant/home/trash.svg");
  background-size: 40px;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  background-blend-mode: multiply;
  background-color: #f0edf2b4;
}
.food_info {
  cursor: pointer;
}
</style>
