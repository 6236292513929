<template>
  <div class="login">
    <v-snackbar
      color="#ff6a4d"
      max-width="5"
      width="5"
      dark
      v-model="global_error.show_message"
    >
      {{ global_error.message }}

      <template :dark="true" v-slot:action="{ attrs }">
        <v-btn
          color="#fafafa"
          text
          v-bind="attrs"
          @click="global_error.show_message = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <div class="header">
      <div class="container">
        <translate-btn></translate-btn>
        <div class="light-banner"></div>
        <span></span>
      </div>
    </div>
    <div class="content">
      <h1>{{ $t("registration.sign_in") }}</h1>
      <div class="input">
        <label class="active" for="email">{{ $t("information.email") }}</label>
        <input v-model="email" class="active" type="email" id="email" />
      </div>
      <div class="input">
        <label class="active" for="password">{{
          $t("registration.password")
        }}</label>
        <input
          v-model="password"
          class="active"
          type="password"
          name="password"
          id="password"
          v-on:keyup.enter="login()"
        />
        <div
          @click="showPassword('password')"
          :class="[eye ? 'show_password' : 'hide_password', 'icon']"
        ></div>
      </div>
      <div @click="showModal('contact_us_modal')" class="forgot">
        {{ $t("registration.forgot_password") }}
      </div>
      <button @click="login()" class="fill">
        {{ $t("registration.sign_in") }}
      </button>
      <div @click="showModal('contact_us_modal')" class="contact">
        {{ $t("registration.want_be_member") }}
        <u>{{ $t("settings.contact") }} </u>
      </div>
    </div>
    <div id="blur_background" class="blur-bg">
      <contact-us-modal></contact-us-modal>
    </div>
  </div>
</template>
<script>
import ContactUsModal from "../components/restaurant/ContactUsModal.vue";
import TranslateBtn from "../components/common/TranslateBtn.vue";

export default {
  components: {
    ContactUsModal,
    TranslateBtn,
  },
  mounted() {
    this.iOSBottomGapFixer(".login");
  },
  methods: {
    showPassword(password_id) {
      this.eye = this.toggleShowPassword(password_id);
    },
    login() {
      let data = {
        email: this.email,
        password: this.password,
      };
      this.global_loading.show = true;
      this.$http
        .post(this.$hostname + "/login", data)
        .then((resp) => {
          this.successRequest();
          this.$store.dispatch("login", {
            auth: true,
          });

          localStorage.setItem("auth", true);

          localStorage.restaurant_token = resp.data.token;

          this.restaurant_token.config.headers.Authorization =
            "Bearer " + localStorage.restaurant_token;
          this.$router.push("/orders");
          // if (resp.data.restaurant_user_type == "waiter") {
          // } else {
          //   this.$router.push("/");
          // }
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      eye: true,
      email: "",
      password: "",
    };
  },
};
</script>
<style scoped>
.login {
  overflow: hidden;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-image: url("../assets/common/background.jpg"),
    linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 99.99%);
  background-blend-mode: saturation;
  background-size: cover;
  /* -webkit-padding-after: 80px; */
}
.header {
  background: transparent;
  position: unset;
}

.container {
  border: none;
  justify-content: space-between;
}

.content {
  margin: 0 auto;
  gap: 12px;
  padding: 0 16px 24px;
  height: auto;
}
h1 {
  font-weight: 500;
  font-size: 32px;
  color: #f5f6f7;
}
.input {
  background: rgba(245, 246, 247, 0.1);
  backdrop-filter: blur(50px);
  position: relative;
}
.input label {
  color: #b3b3b3;
}

.input input {
  color: #f5f6f7;
  background: transparent;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #f5f6f7 !important;
  -webkit-background-clip: text;
}
.forgot {
  font-size: 14px;
  width: 100%;
  text-align: right;
  text-decoration-line: underline;
  color: #f5f6f7;
}
.contact {
  font-size: 14px;
  text-align: center;
  color: #f5f6f7;
}
</style>