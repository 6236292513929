<template>
  <div class="modal" id="extras_category_modal">
    <div class="question">
      <div class="head">
        {{ extra_category.title }}
      </div>

      <button @click="selectAll()" class="secondary">
        {{ has_all_extra_checked ? "Deselect all" : "Select all" }}
      </button>
      {{ hasAllExtraInChecked }}
      <div class="options">
        <div
          class="option"
          v-for="extra in extra_category.items"
          :key="extra.id"
          @click.prevent="setExtras(extra.id)"
        >
          <input
            type="checkbox"
            name="extras"
            :id="extra.title + extra.id"
            :checked="checked_extras.includes(extra.id)"
          />
          <label :for="extra.title + extra.id">{{
            extra.title + " " + formatPrice(extra.price)
          }}</label>
        </div>
      </div>
      <button @click="passExtras()" class="fill">
        {{ $t("order.submit") }}
      </button>

      <!-- {{ checked_extras }} -->
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    setExtras(extra) {
      const index = this.checked_extras.indexOf(extra);

      if (index > -1) {
        this.checked_extras.splice(index, 1);
      } else {
        this.checked_extras.push(extra);
      }
    },
    selectAll() {
      if (!this.has_all_extra_checked) {
        this.clearChecked();

        this.extra_category.items.forEach((element) => {
          this.checked_extras.push(element.id);
        });
      } else {
        this.clearChecked();
      }
    },
    clearChecked() {
      this.extra_category.items.forEach((element) => {
        const index = this.checked_extras.indexOf(element.id);

        if (index > -1) {
          this.checked_extras.splice(index, 1);
        }
      });
    },
    passExtras() {
      this.$emit("passExtras", this.checked_extras);
      this.closeModal("extras_category_modal");
    },
  },
  computed: {
    hasAllExtraInChecked() {
      if (this.extra_category.items) {
        this.extra_category.items.forEach((element) => {
          const index = this.checked_extras.indexOf(element.id);
          if (index > -1) {
            this.has_all_extra_checked = true;
          } else {
            this.has_all_extra_checked = false;
            return 0;
          }
        });
      }
    },
  },
  data() {
    return {
      has_all_extra_checked: false,
    };
  },
  props: {
    extra_category: {},
    checked_extras: [],
  },
};
</script>
<style scoped>
.modal {
  height: 500px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
}
.head {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  width: 100%;
  color: #4d4d4d;
}

.options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.option {
  width: 48.5%;
}

.options label {
  background-color: #ffffff;
  border: 1px solid #f0edf2;
  border-radius: 8px;
  width: 31%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #737373;
  font-weight: 400;
  font-size: 14px;
}

.option label {
  width: 100%;
}

.options input[type="checkbox"]:checked + label {
  border: 1px solid #5a187a;
  color: #5a187a;
}

.options input[type="checkbox"] {
  appearance: none;
  display: none;
  background-image: none;
}

.fill {
  background-color: #5a187a;
  border: 1px solid #5a187a;
  max-width: 768px;
  min-height: 40px;
  width: 100%;
  border-radius: 8px;
}

.secondary {
  font-weight: 500;
  font-size: 14px;
  background-color: #f0edf2;
  min-height: 40px;
  width: 100%;
  border-radius: 8px;
  color: #5a187a;
}
</style>