<template>
  <v-snackbar
    color="#5a187a"
    max-width="5"
    width="5"
    dark
    v-model="global_error.show"
    timeout="8000"
  >
    {{ global_error.message }}

    <template :dark="true" v-slot:action="{ attrs }">
      <v-btn
        color="#fafafa"
        text
        v-bind="attrs"
        @click="global_error.show = false"
      >
        {{ $t("main_page.close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {};
</script>