<template>
  <div class="reservation">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/reservation-info'"></back-button>
        <div class="title">{{ $t("reserve.invalid_times") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <button @click="editInvalidTime({})" class="fill">
        {{ $t("reserve.add_invalid_time") }}
      </button>
      <div
        v-for="(invalid_time, index) in invalid_times"
        :key="index"
        class="btn"
      >
        <div class="head">
          <div class="active-text">{{ invalid_time.reason }}</div>
          <div class="actions">
            <button
              @click="deleteInvalidTime(invalid_time)"
              class="icon delete"
            ></button>
            <button
              @click="editInvalidTime(invalid_time)"
              class="icon edit"
            ></button>
          </div>
        </div>
        <div class="description">
          <span>
            {{
              $t("reserve.start") + ": " + formatDatetime(invalid_time.start_at)
            }}
          </span>
          <span>
            {{ $t("reserve.end") + ": " + formatDatetime(invalid_time.end_at) }}
          </span>
        </div>
      </div>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
      <invalid-time-modal
        @closed_edit_modal="closedModal"
        :invalid_time="selected_invalid_time"
      ></invalid-time-modal>
      <delete-invalid-time-modal
        @closed_delete_model="closedModal"
        :invalid_time="deleting_invalid_time"
      ></delete-invalid-time-modal>
    </div>
  </div>
</template>
<script>
import CallsModal from "../../components/restaurant/CallsModal.vue";
import CallWaiter from "../../components/restaurant/CallWaiter.vue";
import InvalidTimeModal from "../../components/restaurant/InvalidTimeModal.vue";
import DeleteInvalidTimeModal from "../../components/restaurant/DeleteInvalidTimeModal.vue";
import BackButton from "../../components/common/BackButton.vue";

export default {
  components: {
    CallWaiter,
    CallsModal,
    BackButton,
    InvalidTimeModal,
    DeleteInvalidTimeModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/invalid-times", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.invalid_times = resp.data.result;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    editInvalidTime(invalid_time) {
      this.selected_invalid_time = invalid_time;
      this.showModal("invalid_time_modal");
    },
    deleteInvalidTime(invalid_time) {
      this.deleting_invalid_time = invalid_time;
      this.showModal("delete_invalid_time_modal");
    },
    closedModal(val) {
      if (val) {
        this.fetch();
      }
    },
  },
  data() {
    return {
      invalid_times: [],
      selected_invalid_time: {},
      deleting_invalid_time: {},
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 100vh;
  overflow-y: scroll;
  gap: 8px;
}
.btn {
  padding: 12px 8px;
  font-size: 14px;
  flex-direction: column;
  gap: 12px;
}

.fill {
  min-height: 48px;
}

.head,
.description {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.edit {
  background-image: url("../../assets/restaurant/home/pen_gray.svg");
}

.delete {
  background-image: url("../../assets/restaurant/home/trash_gray.svg");
}
</style>
