<template>
  <div class="reservation">
    <div class="header">
      <div class="container">
        <span></span>
        <div class="title">{{ $t("navbar.reservation") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content" v-if="error_message">{{ error_message }}</div>
    <div class="content" v-else>
      <div class="options">
        <router-link class="btn" to="/reservation-history">
          {{ $t("home.history") }}
        </router-link>

        <router-link class="btn fill" to="/create-reservation">
          {{ $t("reserve.walkin_call") }}
        </router-link>
      </div>
      <div
        v-for="reserve in restaurant_reservations.pending"
        :key="reserve.id"
        class="btn"
      >
        <div class="row">
          <div class="datetime">{{ formatDatetime(reserve.reserved_at) }}</div>
          <div class="people">
            {{
              reserve.people == 1
                ? reserve.people + " " + $t("reserve.person")
                : reserve.people + " " + $t("reserve.people")
            }}
          </div>
        </div>
        <div class="row">
          <img :src="reserve.reservation_source" width="18" height="18" />
          <div class="people">
            {{
              reserve.table
                ? reserve.table
                : reserve.table_combination
                ? reserve.table_combination
                : ""
            }}
          </div>
        </div>
        <div class="row">
          <div class="name">
            {{ reserve.user_id ? reserve.user.name : reserve.full_name }}
          </div>
          <a
            :href="
              'tel:' +
              (reserve.user_id
                ? reserve.user.phone_number
                : reserve.phone_number)
            "
          >
            <div class="phone">
              {{
                reserve.user_id
                  ? reserve.user.phone_number
                  : reserve.phone_number
              }}
            </div>
          </a>
        </div>

        <div class="row">
          <div :class="[reserve.status]">
            {{
              reserve.status == "pending"
                ? $t("reserve.pending")
                : reserve.status == "cancelled"
                ? $t("reserve.cancelled")
                : $t("reserve.confirmed")
            }}
          </div>
          <div class="actions">
            <button
              @click="confirm(reserve)"
              v-if="reserve.status == 'pending'"
              class="confirm"
            >
              {{ $t("reserve.confirm") }}
            </button>
            <button
              @click="reject(reserve)"
              v-if="reserve.status == 'pending'"
              class="reject"
            >
              {{ $t("reserve.reject") }}
            </button>
          </div>
        </div>
        <div class="row note">
          <i>
            {{ reserve.message }}
          </i>
        </div>

        <div class="row">
          <v-textarea
            @change="saveRestaurantNote(reserve)"
            v-model="reserve.restaurant_message"
            filled
            rows="1"
            :label="$t('reserve.restaurant_note') + ':'"
            maxlength="1000"
            background-color="#ffffff"
          ></v-textarea>
        </div>
      </div>
      <div
        v-for="reserve in restaurant_reservations.confirm"
        :key="reserve.id"
        class="btn"
      >
        <div class="row">
          <div class="datetime">{{ formatDatetime(reserve.reserved_at) }}</div>
          <div class="people">
            {{
              reserve.people == 1
                ? reserve.people + " " + $t("reserve.person")
                : reserve.people + " " + $t("reserve.people")
            }}
          </div>
        </div>
        <div class="row">
          <img :src="reserve.reservation_source" width="18" height="18" />
          <div class="people">
            {{
              reserve.table
                ? reserve.table
                : reserve.table_combination
                ? reserve.table_combination
                : ""
            }}
          </div>
        </div>
        <div class="row">
          <div class="name">
            {{ reserve.user_id ? reserve.user.name : reserve.full_name }}
          </div>
          <a
            :href="
              'tel:' +
              (reserve.user_id
                ? reserve.user.phone_number
                : reserve.phone_number)
            "
          >
            <div class="phone">
              {{
                reserve.user_id
                  ? reserve.user.phone_number
                  : reserve.phone_number
              }}
            </div>
          </a>
        </div>

        <div class="row">
          <div :class="[reserve.status]">
            {{
              reserve.status == "pending"
                ? $t("reserve.pending")
                : reserve.status == "cancelled"
                ? $t("reserve.cancelled")
                : $t("reserve.confirmed")
            }}
          </div>
          <div class="actions">
            <button
              @click="confirm(reserve)"
              v-if="reserve.status == 'pending'"
              class="confirm"
            >
              {{ $t("reserve.confirm") }}
            </button>
            <button
              @click="reject(reserve)"
              v-if="reserve.status == 'pending'"
              class="reject"
            >
              {{ $t("reserve.reject") }}
            </button>
          </div>
        </div>
        <div class="row note">
          <i>
            {{ reserve.message }}
          </i>
        </div>

        <div class="row">
          <v-textarea
            @change="saveRestaurantNote(reserve)"
            v-model="reserve.restaurant_message"
            filled
            rows="1"
            :label="$t('reserve.restaurant_note') + ':'"
            maxlength="1000"
            background-color="#ffffff"
          ></v-textarea>
        </div>
      </div>
      <empty-reserves
        v-if="
          restaurant_reservations.pending.length == 0 &&
          restaurant_reservations.confirm.length == 0
        "
      ></empty-reserves>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import CallsModal from "../components/restaurant/CallsModal.vue";
import CallWaiter from "../components/restaurant/CallWaiter.vue";
import EmptyReserves from "../components/restaurant/EmptyReserves.vue";
export default {
  components: { CallWaiter, CallsModal, EmptyReserves },
  mounted() {
    this.fetchReservations();

    Pusher.logToConsole = false;

    const vm = this;
    let reservation_channel = this.$pusher.subscribe(
      "reservations-" + this.decodeUserIdByToken()
    );

    reservation_channel.bind("reservations", function (data) {
      if (data.id) {
        vm.fetchReservations();
      }
    });
  },
  methods: {
    confirm(reservation) {
      let data = {};
      this.global_loading.show = true;
      this.$http
        .patch(
          this.$hostname + "/confirm-reservation/" + reservation.id,
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.fetchReservations();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    reject(reservation) {
      let data = {};

      this.global_loading.show = true;
      this.$http
        .patch(
          this.$hostname + "/reject-reservation/" + reservation.id,
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.fetchReservations();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    saveRestaurantNote(reservation) {
      let data = {
        restaurant_message: reservation.restaurant_message,
      };

      this.global_loading.show = true;
      this.$http
        .patch(
          this.$hostname + "/save-reservation-note/" + reservation.id,
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          // this.reserves = resp.data.reservations;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      reserves: [],
      error_message: "",
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.reservation {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.content {
  height: 100%;
  /* overflow-y: scroll; */
  /* padding-bottom: 75px; */
  gap: 8px;
}
.btn {
  padding: 12px 8px;
  font-size: 14px;
  flex-direction: column;
  gap: 12px;
  color: #4d4d4d;
  background-color: #fbf5f5;
}

.options .btn {
  width: 49%;
  justify-content: center;
}
.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.datetime,
.people {
  color: #776282;
}
.name,
.phone {
  color: #b3b3b3;
}
.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 6px;
}

.fill {
  width: 49%;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  height: 48px;
  color: #ffffff;
  background-color: #412250;
  border: 1px solid #412250;
}

.pending {
  background-color: #f7f31849;
  color: #bcb601;
  padding: 4px 6px;
  border-radius: 12px;
  text-transform: capitalize;
}
.confirmed {
  background-color: #36f71849;
  color: #01bc0d;
  padding: 4px 6px;
  border-radius: 12px;
  text-transform: capitalize;
}

.cancelled {
  background-color: #f7181849;
  color: #bc0101;
  padding: 4px 6px;
  border-radius: 12px;
  text-transform: capitalize;
}
.actions {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.confirm {
  padding: 4px 8px;
  width: 49%;
  background-color: #36f71853;
  border-radius: 4px;
}

.reject {
  padding: 4px 8px;
  width: 49%;
  background-color: #f7181849;
  border-radius: 4px;
}

.note {
  font-weight: 500;
  font-size: 16px;
  color: tomato;
  justify-content: center;
}
</style>
