export const login = ({ commit }, { auth }) => {
  commit('LOGIN', { auth })
}

export const setName = ({ commit }, { name }) => {
  commit('SET_NAME', { name })
}

export const setEmail = ({ commit }, { email }) => {
  commit('SET_EMAIL', { email })
}

export const setToken = ({ commit }, { token }) => {
  commit('SET_TOKEN', { token })
}

// just for restaurant
export const setUserId = ({ commit }, { user }) => {
  commit('SET_USER_ID', { user })
}
