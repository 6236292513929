<template>
  <div class="rate">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>
        <div class="title">{{ $t("home.rate_data") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <!-- <rate-chart></rate-chart> -->
      <vue-apex-charts
        style="margin: auto"
        type="donut"
        :options="chartOptions"
        :series="series"
        :key="5"
      ></vue-apex-charts>
      <div class="detail">
        <div
          v-for="(rate, index) in rates"
          :key="index"
          v-if="rate"
          class="btn"
        >
          <div class="key">
            <div
              :class="[
                'icon',
                rate.index >= 4 ? 'green' : rate.index <= 2 ? 'red' : 'yellow',
              ]"
            ></div>
            {{ rate.index + " " + $t("home.star") }}
          </div>
          <div class="value">{{ calcPercentage(rate.votes) + "%" }}</div>
        </div>
      </div>
      <h4>
        <div class="icon tip"></div>
        {{ $t("home.comments") }}
      </h4>
      <div class="comments">
        <div v-for="comment in comments" :key="comment.id" class="btn comment">
          <div class="description">
            {{ comment.comment }}
          </div>
          <div class="footer">
            <div class="rate">
              <div class="icon star"></div>
              <span>
                {{ comment.vote + " / 5" }}
              </span>
            </div>
            <div class="datetime">
              {{ formatDatetime(comment.created_at) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import CallWaiter from "../../components/restaurant/CallWaiter.vue";
import VueApexCharts from "vue-apexcharts";
import CallsModal from "../../components/restaurant/CallsModal.vue";

export default {
  components: {
    BackButton,
    CallWaiter,
    VueApexCharts,
    CallsModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      const vm = this;
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/rates", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.comments = resp.data.comments;

          this.rates = resp.data.rates;

          this.rates.forEach((element) => {
            if (element) {
              this.total_votes += element.votes;
              this.series.push(element.votes);
            } else {
              this.series.push(0);
            }
          });

          vm.restaurant_average.data = resp.data.average;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    calcPercentage(val) {
      return ((val * 100) / this.total_votes).toFixed(2);
    },
    countOfReviews() {
      return this.total_votes;
    },
    getAverage() {
      return this.average_rates;
    },
  },
  updated() {
    this.getAverage();
  },
  data() {
    return {
      rates: [],
      series: [],
      total_votes: 0,
      average_rates: 0,
      comments: [],
      chartOptions: {
        reviews: 1,
        type: "donut",
        labels: ["★", "★★", "★★★", "★★★★", "★★★★★"],
        colors: ["#ee596b", "#ee596b", "#edc932", "#78d0b1", "#78d0b1"],
        title: {
          show: false,
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                offsetX: 0,
                offsetY: 0,
                width: 320,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        plotOptions: {
          fontSize: "18px",
          pie: {
            size: 50,
            donut: {
              size: "70%",
              labels: {
                show: true,
                value: {
                  show: true,
                  formatter: (val) => {
                    return `${this.calcPercentage(val)}%`;
                  },
                },
                total: {
                  show: true,
                  label: `${this.restaurant_average.data}/5`,
                  fontSize: "28px",
                  formatter: (val) => {
                    let reviews = this.countOfReviews();
                    return `${reviews}  ${this.$t("menu.reviews")}`;
                  },
                },
              },
            },
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.header {
  z-index: 500;
}
.container {
  justify-content: space-between;
}
.content {
  height: 100vh;
  overflow-y: scroll;
  padding-top: 120px;
}
.detail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 24px;
}
.btn {
  padding: 12px;
}
.key {
  display: flex;
  gap: 8px;
  align-items: center;
}
.red {
  background-color: #ee596b;
}

.yellow {
  background-color: #edc932;
}

.green {
  background-color: #78d0b1;
}
.icon {
  width: 24px;
  border-radius: 4px;
}
h4 {
  padding: 16px 0;
  display: flex;
  align-items: center;
}

.comments {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.comment {
  padding: 12px;
  flex-direction: column;
  gap: 12px;
}

.description {
  font-size: 16px;
  color: #737373;
  text-align: left;
  width: 100%;
}
.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.footer .rate {
  width: 50%;
}
.datetime {
  width: 100%;
  font-size: 12px;
  color: #b3b3b3;
  text-align: right;
}
</style>