<template>
  <div id="timer_modal" class="modal">
    <div class="question">
      <h3>{{ $t("order.prepration_duration") }}</h3>
      <div class="times">
        <div class="option">
          <input
            v-model="duration"
            value="10"
            type="radio"
            name="duration"
            id="ten"
          />
          <label for="ten">10 {{ $t("order.minutes") }} </label>
        </div>
        <div class="option">
          <input
            v-model="duration"
            value="20"
            type="radio"
            name="duration"
            id="twenty"
          />
          <label for="twenty">20 {{ $t("order.minutes") }}</label>
        </div>
        <div class="option">
          <input
            v-model="duration"
            value="30"
            type="radio"
            name="duration"
            id="thirty"
          />
          <label for="thirty">30 {{ $t("order.minutes") }}</label>
        </div>
      </div>

      <div class="input">
        <label class="deactive" for="price">{{
          $t("order.custom_duration")
        }}</label>
        <input
          class="active"
          type="number"
          id="price"
          v-model="duration"
          v-on:keyup.enter="confirm()"
        />
        <span>{{ $t("order.minutes") }}</span>
      </div>
      <div v-if="order_processing.data.order_type == 'delivery'" class="note">
        *{{ $t("order.default_delivery_time") }}.
      </div>
      <button @click="confirm" class="fill">{{ $t("order.submit") }}</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      duration: "",
    };
  },
  methods: {
    confirm() {
      this.setDuration(this.duration);
    },
  },
};
</script>
<style scoped>
.modal {
  height: 350px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
h3 {
  font-size: 18px;
  text-align: center;
  color: #737373;
}
.times {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
}
.option {
  width: 33%;
  display: flex;
}
input#ten,
input#twenty,
input#thirty {
  appearance: none;
}

input#ten ~ label,
input#twenty ~ label,
input#thirty ~ label {
  padding: 10px 5px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  border: 1px solid rgba(38, 50, 56, 0.5);
  color: #707070;
  cursor: pointer;
}
input#ten:checked ~ label {
  color: #5a187a;
  border: 1px solid #5a187a;
}
input#twenty:checked ~ label {
  color: #5a187a;
  border: 1px solid #5a187a;
}

input#thirty:checked ~ label {
  color: #5a187a;
  border: 1px solid #5a187a;
}

input#custom {
  width: 50%;
}

.note {
  font-size: 14px;
  color: #5a187a;
}

.fill {
  border: 1px solid #5a187a;
  background-color: #5a187a;
}
</style>