<template>
  <div class="extra-drink">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/menu'"></back-button>
        <div class="title">{{ restaurant_category.title }}</div>
        <h4 @click="deleting()">
          {{ !editing ? $t("profile.edit") : $t("customer_home.done") }}
        </h4>
      </div>
    </div>
    <div class="content">
      <div class="items">
        <div
          class="btn"
          v-for="item in restaurant_category.items"
          :key="item.id"
        >
          <div @click="openModal(item)" class="info">
            <span class="title">
              {{ item.title }}
            </span>
            <span class="price">
              {{ formatPrice(item.price) }}
            </span>
          </div>
          <div
            @click="activeItem(item.id, id)"
            v-if="!editing"
            :class="[item.status == 1 ? 'open' : 'closed', 'status']"
          >
            {{ item.status == 1 ? "Available" : "Unavailable" }}
          </div>
          <div @click="deleteItem(item.id)" v-else class="icon delete"></div>
        </div>
      </div>
      <button @click="openModal({})" class="fill">
        {{ $t("restaurant_menu.add_new_item") }}
      </button>
      <div id="blur_background" class="blur-bg">
        <new-extra-drink-modal
          :category_id="id"
          :category_type="type"
          :item="selected_item"
          :edit="edit"
        ></new-extra-drink-modal>
      </div>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import NewExtraDrinkModal from "../../components/restaurant/NewExtraDrinkModal.vue";

export default {
  components: { BackButton, NewExtraDrinkModal },
  mounted() {
    this.fetchItems(this.id);
  },
  methods: {
    deleting() {
      this.editing = !this.editing;
    },
    deleteItem(item_id) {
      this.global_loading.show = true;

      this.$http
        .delete(
          this.$hostname + "/items/" + item_id,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.fetchItems(this.id);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    openModal(item) {
      item.price = this.replaceDot(item.price);

      this.selected_item = item;

      if (this.selected_item.title) {
        this.edit = true;
      } else {
        this.edit = false;
      }

      this.showModal("extra_drink_modal");
    },
  },
  data() {
    return {
      selected_item: {},
      editing: false,
      edit: false,
    };
  },
  props: {
    type: "",
    id: 0,
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 80vh;
  overflow-y: scroll;
  padding: 72px 16px;
}

.items {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.btn {
  padding: 12px;
  background-color: #fafafa;
}

.delete {
  width: 35px;
  background-color: #ffffff;
  height: 35px;
  border-radius: 8px;
}

.info {
  width: 100%;
}

.title {
  font-size: 16px;
  color: #4d4d4d;
}

.price {
  font-size: 14px;
  color: #b3b3b3;
}

button {
  position: absolute;
  bottom: 68px;
  background-color: #5a187a;
  border: 1px solid #5a187a;
  max-width: 768px;
  width: 92%;
}
</style>