<template>
  <div class="history">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>
        <div class="title">{{ "Voucher" }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <h2>UNDER CONSTRUCTION</h2>
      <div id="blur_background" class="blur-bg">
        <calls-modal></calls-modal>
      </div>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import CallWaiter from "../../components/restaurant/CallWaiter.vue";
import CallsModal from "../../components/restaurant/CallsModal.vue";

export default {
  components: {
    BackButton,
    CallWaiter,
    CallsModal,
  },
  methods: {},
  data() {
    return {};
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 100vh;
  overflow-y: scroll;
}
h2 {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  color: rgb(77, 51, 5);
}
</style>