<template>
  <div id="reservation_hours_modal" class="modal">
    <div class="question">
      <div class="times">
        <div class="days" v-for="day in reservation_times" :key="day.id">
          <input
            type="checkbox"
            name="day"
            :id="'day' + day.id"
            v-model="day.status"
            :checked="day.status"
          />
          <label
            v-if="day.day == 'monday'"
            :for="'day' + day.id"
            :class="[day.status ? 'checked' : 'unchecked']"
          >
            {{ $t("info.mon") }}
          </label>
          <label
            v-if="day.day == 'tuesday'"
            :for="'day' + day.id"
            :class="[day.status ? 'checked' : 'unchecked']"
          >
            {{ $t("info.tue") }}
          </label>
          <label
            v-if="day.day == 'wednesday'"
            :for="'day' + day.id"
            :class="[day.status ? 'checked' : 'unchecked']"
          >
            {{ $t("info.wed") }}
          </label>
          <label
            v-if="day.day == 'thursday'"
            :for="'day' + day.id"
            :class="[day.status ? 'checked' : 'unchecked']"
          >
            {{ $t("info.thu") }}
          </label>
          <label
            v-if="day.day == 'friday'"
            :for="'day' + day.id"
            :class="[day.status ? 'checked' : 'unchecked']"
          >
            {{ $t("info.fri") }}
          </label>
          <label
            v-if="day.day == 'saturday'"
            :for="'day' + day.id"
            :class="[day.status ? 'checked' : 'unchecked']"
          >
            {{ $t("info.sat") }}
          </label>
          <label
            v-if="day.day == 'sunday'"
            :for="'day' + day.id"
            :class="[day.status ? 'checked' : 'unchecked']"
          >
            {{ $t("info.sun") }}
          </label>
          <div class="hour">
            <input type="time" v-model="day.from" />
            -
            <input step="600" type="time" v-model="day.to" />
          </div>
        </div>
      </div>
      <button @click="save" class="fill">{{ $t("information.save") }}</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    reservation_times: [],
  },
  methods: {
    save() {
      this.$emit("reservation_times", this.reservation_times);
      this.closeModal("reservation_hours_modal");
    },
  },
};
</script>

<style scoped>
.modal {
  height: 510px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.times {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.days {
  display: flex;
  justify-content: space-between;
}
input[type="checkbox"] {
  display: none;
}
.checked,
.unchecked {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.unchecked {
  color: #737373;
  background-color: #ffeeeb;
}

.checked {
  background-color: #5a187a;
  color: #f5f6f7;
}

input[type="time"] {
  background-color: #f5f6f7;
  color: #b3b3b3;
  padding: 12px;
  border-radius: 8px;
  border: transparent 1px solid;
  width: 45%;
}

input[type="time"]:focus {
  border: #5a187a 1px solid;
}

input[type="time"]::-webkit-datetime-edit-fields-wrapper {
  cursor: pointer;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  width: 0;
  display: none;
}

.fill {
  border: 1px solid #5a187a;
  background-color: #5a187a;
}
.hour {
  width: 270px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}
</style>
