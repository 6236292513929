<template>
  <div class="reservation">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/reservation-info'"></back-button>
        <div class="title">{{ $t("reserve.tables") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <div class="options">
        <router-link class="btn" to="/table-combinations">
          {{ $t("reserve.table_combos") }}
        </router-link>
        <button @click="editTable({})" class="fill">
          {{ $t("reserve.add_table") }}
        </button>
      </div>

      <div v-for="(table, index) in tables" :key="index" class="btn">
        <div class="head">
          <div class="active-text">{{ table.name }}</div>
          <div class="actions">
            <button @click="deleteTable(table)" class="icon delete"></button>
            <button @click="editTable(table)" class="icon edit"></button>
          </div>
        </div>
        <div class="description">
          <span>
            {{ $t("reserve.capacity") + ": " + table.capacity }}
          </span>
          <span>
            {{ $t("reserve.minimum") + ": " + table.minimum }}
          </span>
        </div>
      </div>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
      <table-modal
        @closed_edit_modal="closedModal"
        :table="selected_table"
      ></table-modal>
      <delete-table-modal
        @closed_delete_model="closedModal"
        :table="deleting_table"
      ></delete-table-modal>
    </div>
  </div>
</template>
<script>
import CallsModal from "../../components/restaurant/CallsModal.vue";
import CallWaiter from "../../components/restaurant/CallWaiter.vue";
import TableModal from "../../components/restaurant/TableModal.vue";
import DeleteTableModal from "../../components/restaurant/DeleteTableModal.vue";
import BackButton from "../../components/common/BackButton.vue";

export default {
  components: {
    CallWaiter,
    CallsModal,
    BackButton,
    TableModal,
    DeleteTableModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/tables", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.tables = resp.data.result;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    editTable(table) {
      this.selected_table = table;
      this.showModal("table_modal");
    },
    deleteTable(table) {
      console.log(table);
      this.deleting_table = table;
      this.showModal("delete_table_modal");
    },
    closedModal(val) {
      if (val) {
        this.fetch();
      }
    },
  },
  data() {
    return {
      tables: [],
      selected_table: {},
      deleting_table: {},
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 100vh;
  overflow-y: scroll;
  gap: 8px;
}
.btn {
  padding: 12px 8px;
  font-size: 14px;
  flex-direction: column;
  gap: 12px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 6px;
}

.options .btn {
  width: 49%;
  justify-content: center;
}

.fill {
  width: 49%;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  height: 48px;
  color: #ffffff;
  background-color: #412250;
  border: 1px solid #412250;
}

.head,
.description {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.edit {
  background-image: url("../../assets/restaurant/home/pen_gray.svg");
}

.delete {
  background-image: url("../../assets/restaurant/home/trash_gray.svg");
}

.btn {
  padding: 12px 8px;
  font-size: 14px;
  flex-direction: column;
  gap: 12px;
  color: #4d4d4d;
}
</style>
