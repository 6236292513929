<template>
  <div id="reject_modal" class="modal">
    <div class="question">
      <h3>{{ $t("order.reason_rejection") }}</h3>
      <textarea
        name="reason"
        :placeholder="$t('order.type_here')"
        id="reason"
        cols="30"
        rows="10"
        v-model="reason"
        v-on:keyup.enter="reject()"
      ></textarea>
      <button @click="reject" :class="[valid ? '' : 'invalid', 'fill']">
        {{ $t("order.submit") }}
        <!-- {{ valid }} -->
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reason: "",
    };
  },
  computed: {
    valid() {
      if (this.reason.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    reject() {
      if (this.valid) {
        let data = {
          order_id: this.order_processing.data.id,
          reason: this.reason,
        };
        this.global_loading.show = true;
        this.$http
          .patch(
            this.$hostname + "/reject-order",
            data,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.closeModal("reject_modal");
            this.fetchOrders(
              this.setOrderType(this.order_processing.data.order_type)
            );
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
  },
};
</script>
<style scoped>
.modal {
  height: 350px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

h3 {
  font-size: 18px;
  text-align: center;
  color: #737373;
}

textarea {
  background-color: #f5f6f7;
  border-radius: 8px;
  padding: 12px;
  outline: none;
  resize: none;
  height: 72px;
  width: 100%;
}

textarea::placeholder {
  color: #b3b3b3;
}
.fill {
  border: 1px solid #5a187a;
  background-color: #5a187a;
}

.invalid {
  border-color: #b3b3b3;
  color: #b3b3b3;
  background-color: #f5f6f7;
}
</style>