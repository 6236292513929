<template>
  <button @click="printOrder(order)" class="icon print"></button>
</template>
<script>
export default {
  props: {
    order: {},
  },
};
</script>
<style scoped>
.print {
  background-image: url("../../assets/common/print.svg");
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-size: 24px;
  width: 28px;
  height: 28px;
  background-color: #f0edf2;
  border-radius: 8px;
}
</style>